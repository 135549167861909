import { useTranslation } from 'react-i18next';
const Signatures = ({ report }) => {
  const { t } = useTranslation();
  const formatName = (name) => {
    if (!name) return null;
    if (name.length <= 14) return name;

    const split_name = name.split(' ');
    return split_name.map((part, index) => (index === 0 ? part : `${part.charAt(0)}.`)).join(' ');
  };

  const renderSignature = (name) => <div className='sign-font'>{name}</div>;

  const renderSignatureBlock = (signerTitle, data) => (
    <div className='signature-block'>
      <p style={{ color: '#225968', margin: '0 0 10px', fontSize: '15px', fontWeight: 'bold' }}>
        {signerTitle}
      </p>
      {data?.sign_name ? renderSignature(data.sign_name) : renderSignature(' ')}
      <div
        style={{
          borderTop: '2px solid #225968',
          width: '80%',
          margin: '10px auto',
          paddingTop: '1rem',
          textAlign: 'center',
        }}
      >
        <p style={{ color: '#225968', margin: '0', fontSize: '18px', fontWeight: 'bold' }}>
          {data ? `${data.reproved ? t('reproved_by') : ''} ${data.name?.toUpperCase()}` : ' '}
        </p>
        <p style={{ color: '#225968', margin: '0', fontSize: '12px' }}>
          {data?.email?.toUpperCase() || ' '}
        </p>
        <p style={{ color: '#225968', margin: '0', fontSize: '12px' }}>
          {data?.company?.toUpperCase() || ' '}
        </p>
      </div>
    </div>
  );
  return (
    <div className='report-table'>
      <div className='report-table-title'>
        <p style={{ margin: '0 0 0 10px', color: 'white', fontWeight: '500' }}>{t('signatures')}</p>
      </div>
      <div className='report-table-content-sign'>
        {renderSignatureBlock(t('executor'), {
          name: report?.employee?.properties.find((prop) => prop.key === 'name')?.value,
          sign_name: formatName(
            report?.employee?.properties.find((prop) => prop.key === 'name')?.value,
          ),
          email: report?.employee?.email,
          company: report?.employee?.properties.find((prop) => prop.key === 'company')?.value,
        })}

        {report.report.signatures?.map((signature) =>
          signature.role
            ? renderSignatureBlock(t(`report_${signature.anchor}`), {
                name: signature?.role?.name,
                sign_name: formatName(signature?.role?.name),
                email: signature?.role?.email,
                company: signature?.role?.company,
                reproved: !signature?.approve,
              })
            : renderSignatureBlock(t(`report_${signature.anchor}`)),
        )}
      </div>
    </div>
  );
};

export default Signatures;
