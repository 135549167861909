import './style.css';

import upload from '../../../../images/file-upload.svg';
import manually from '../../../../images/add-manually.svg';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import useMaintenanceState from '../../../../atoms/variables';
import useTemplateState from '../../../../../Template/atoms/variables';
import UploadSheetModal from './uploadSheetModal';

const NewInterconnectionModal = ({ setForceReload }) => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { plantId } = useParams();
  const {
    setModalOpen,
    setModalConfirm,
    setModalContent,
    setModalTitle,
    setCleanOnCancel,
    setModalStyle,
  } = useTemplateState();

  const { setViewDetailsItem } = useMaintenanceState();

  return (
    <div className='newInterconnectionOptions'>
      <div
        className='newInterconnectionOption'
        onClick={() => {
          setViewDetailsItem({
            id: 'newInterconnection',
            name: t('new_interconnection'),
            category: null,
            properties: [
              {
                label: t('name'),
                type: 'text',
                value: t('new_interconnection'),
                main: true,
                required: true,
                key: 'name',
              },
              {
                label: t('category'),
                type: 'singleSelect',
                value: null,
                options: [],
                main: true,
                allowEdit: true,
                required: true,
                key: 'category',
              },
              {
                label: t('system'),
                type: 'multiSelect',
                value: null,
                options: [],
                main: true,
                allowEdit: true,
                required: false,
                key: 'system',
              },
              {
                label: t('organization'),
                type: 'singleSelect',
                value: null,
                options: [],
                main: true,
                allowEdit: true,
                required: true,
                key: 'organization',
              },
              {
                label: t('department'),
                type: 'singleSelect',
                value: null,
                options: [],
                main: true,
                allowEdit: true,
                required: true,
                key: 'department',
              },
              {
                label: t('manufacturer'),
                type: 'singleSelect',
                value: null,
                options: [],
                main: true,
                allowEdit: true,
                required: true,
                key: 'manufacturer',
              },
              {
                label: t('cost_center'),
                type: 'singleSelect',
                value: null,
                options: [],
                main: true,
                allowEdit: true,
                required: true,
                key: 'cost_center',
              },
              {
                label: t('model_number'),
                type: 'text',
                value: null,
                main: true,
                required: true,
                key: 'model',
              },
              {
                label: t('installation_date'),
                type: 'date',
                value: null,
                main: true,
                required: true,
                key: 'installation_date',
              },
              {
                label: t('out_of_service'),
                type: 'bool',
                value: false,
                main: true,
                allowEdit: true,
                required: true,
                key: 'out_of_service',
              },
            ],
          });
          setModalOpen(false);
          navigate(`/maintenance/plants/${plantId}/interconnections/newInterconnection`);
        }}
      >
        <div className='headerImage'>
          <img src={manually} />
        </div>
        <div className='body'>
          <div className='title'>{t('manual_title')}</div>
          <div className='sub'>{t('manual_sub')}</div>
        </div>
      </div>
      <div
        className='newInterconnectionOption'
        onClick={() => {
          setModalTitle(t('interconnection_details'));
          setModalContent(<UploadSheetModal setForceReload={setForceReload} />);
        }}
      >
        <div className='headerImage'>
          <img src={upload} />
        </div>
        <div className='body'>
          <div className='title'>{t('upload_title')}</div>
          <div className='sub'>{t('upload_sub')}</div>
        </div>
      </div>
    </div>
  );
};

export default NewInterconnectionModal;
