import React, { useEffect } from 'react';
import { createBrowserRouter, RouterProvider, Navigate, useNavigate } from 'react-router-dom';

import Outlet from './Template/Outlet';
import ErrorPage from './Template/pages/ErrorPage';
import AuthCallback from './Template/pages/AuthCallback';
import ProtectedModule from './Template/pages/ProtectedModule';

import { SafetyCopilotOutlet } from './SafetyCopilot/Outlet/Outlet';
import SafetyCopilotRoutes from './SafetyCopilot/routes';

import { VirtualEngineeringOutlet } from './VirtualEnginner/Outlet/Outlet';
import VirtualEnginnerRoutes from './VirtualEnginner/routes';

import { MaintenanceOutlet } from './Maintenance/Outlet/Outlet';
import MaintenanceRoutes from './Maintenance/routes';

import { SettingsOutlet } from './Settings/Outlet/Outlet';
import SettingsRoutes from './Settings/routes';
import DynamicRedirect from './Template/pages/DynamicRedirect';

export default function Routes() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Outlet />,
      errorElement: <Navigate to='/error' replace />,
      children: [
        // Rota de índice para redirecionar '/'
        {
          index: true,
          element: <DynamicRedirect />,
        },
        {
          path: 'auth/callback',
          element: <AuthCallback />,
        },
        {
          path: '/error',
          element: <ErrorPage />,
        },
        {
          element: <ProtectedModule />,
          children: [
            {
              path: 'maintenance',
              element: <MaintenanceOutlet />,
              children: MaintenanceRoutes,
            },
            {
              path: 'virtual-engineer',
              element: <VirtualEngineeringOutlet />,
              children: VirtualEnginnerRoutes,
            },
            {
              path: 'safety-copilot',
              element: <SafetyCopilotOutlet />,
              children: SafetyCopilotRoutes,
            },
            {
              path: 'settings',
              element: <SettingsOutlet />,
              children: SettingsRoutes,
            },
          ],
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}
