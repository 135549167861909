import React from 'react';
import { Button, Result } from 'antd';
import { useRouteError, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function ErrorPage({ customMessage }) {
  const navigate = useNavigate();
  const error = useRouteError();
  // console.error(error);

  const { t } = useTranslation();

  return (
    <Result
      status={customMessage ? '403' : error?.status || '500'}
      title={customMessage ?? 'Alguma coisa deu errado.'}
      subTitle={
        customMessage
          ? t('no_permission')
          : error?.status
            ? `${error?.status} - ${error?.statusText || error?.message}`
            : ''
      }
      extra={
        <Button onClick={() => navigate(-1)} type='primary'>
          Voltar
        </Button>
      }
      style={{ marginTop: '5%' }}
    />
  );
}
