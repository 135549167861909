import { useEffect, useRef, useState } from 'react';

import arrowDown from '../../images/arrowDown.svg';

import './style.css';
import { useTranslation } from 'react-i18next';

const MultiSelect = ({ options, value, style, onChange, disabled }) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    setSelected(
      value
        ? value
            .filter((v, index) => value.findIndex((item) => item.id === v.id) == index)
            .map((v) => options.find((opt) => opt?.id === v?.id)?.id)
        : [],
    );
  }, [options]);

  const handleMultiSelectBehaviour = (value) => {
    let tmp = [...selected];

    if (tmp.includes(value)) {
      tmp = tmp.filter((c) => c != value);
    } else {
      tmp.push(value);
    }

    setSelected(tmp);

    onChange({ target: { value: tmp } });
    return tmp;
  };

  const dropdownRef = useRef(null);
  const handleBlur = (e) => {
    // Verifica se o foco está fora do dropdown e do input
    if (dropdownRef.current && !dropdownRef.current.contains(e.relatedTarget)) {
      setIsOpen(false);
    }
  };

  return (
    <div className='inputContainer'>
      <div
        className='select-container'
        tabIndex={0}
        onBlur={handleBlur}
        ref={dropdownRef}
        onFocus={() => {
          if (!disabled) setIsOpen(true);
        }}
      >
        <div className='inputIconContainer' style={{ right: 0 }}>
          {<img src={arrowDown} style={{ backgroundColor: 'white' }} />}
        </div>
        <div className={`customInput ${disabled ? 'disabled' : ''}`}>
          <div style={{ height: '100%', width: '90%', overflow: 'hidden' }}>
            {selected.length > 0
              ? selected
                  .map((op) => options.find((option) => option.id === op)?.label)
                  .filter((op) => op)
                  .join(', ')
              : t('choose')}
          </div>
        </div>
        {isOpen && (
          <ul className='dropdown-list'>
            {options.map((option, index) => (
              <li
                key={index}
                className={selected.includes(option.id) ? 'selected' : ''}
                onClick={() => handleMultiSelectBehaviour(option.id)}
              >
                {option.label}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default MultiSelect;
