import { useTranslation } from 'react-i18next';
import { CopyOutlined } from '@ant-design/icons';

import '../style.css';
import { notification } from 'antd';

const TokenPreviewer = ({ token }) => {
  const { t } = useTranslation();
  const [notificationApi, contextHolder] = notification.useNotification();

  return (
    <div className='tokenPreviewerContainer'>
      {contextHolder}
      <div
        className='token'
        onClick={async () => {
          await navigator.clipboard.writeText(token);
          notificationApi.info({
            message: t('copied_to_clipboard'),
            duration: 5,
          });
        }}
      >
        {token}
        <CopyOutlined className='copyIcon' style={{ fontSize: '1.5rem', color: '#00000073' }} />
      </div>
      <div className='tokenPreviewerSubTitle'>{t('share_token')}</div>
    </div>
  );
};

export default TokenPreviewer;
