import { useTranslation } from 'react-i18next';
import CrudPage from '../../../../Template/layoutComponents/CrudPage';

import {
  getManufacturers,
  newManufacturer,
  updateManufacturer,
} from '../../../../Maintenance/helpers/apiCalls';

import useMaintenanceState from '../../../../Maintenance/atoms/variables';

import './style.css';

const Departments = () => {
  const { t } = useTranslation();

  const { orgs } = useMaintenanceState();

  return (
    <div id='manufacturersPage'>
      <CrudPage
        title={t('manufacturer')}
        newText={t('new_manufacturer')}
        detailsLink={'/settings/inventory/manufacturers'}
        defaultItem={{
          new: true,
          label: t('new_manufacturer'),
          properties: [
            {
              label: t('name'),
              type: 'text',
              value: t('new_manufacturer'),
              main: true,
              required: true,
              disabled: false,
              key: 'name',
            },
            {
              label: t('organization_id'),
              type: 'singleSelect',
              value: null,
              options: orgs,
              main: true,
              required: true,
              disabled: false,
              key: 'organization_id',
            },
            {
              label: t('out_of_service'),
              type: 'bool',
              value: false,
              required: true,
              allowEdit: true,
              disabled: false,
              key: 'out_of_service',
            },
          ],
        }}
        getAllItems={getManufacturers}
        newItem={newManufacturer}
        updateItem={updateManufacturer}
      />
    </div>
  );
};

export default Departments;
