import { Button, notification } from 'antd';
import Table from '../../../../../Template/layoutComponents/Table';

import { PlusOutlined } from '@ant-design/icons';

import { getInterconnectionAndUniques } from '../../../../helpers/apiCalls';
import { useMsal } from '@azure/msal-react';
import useTemplateState from '../../../../../Template/atoms/variables';
import useMaintenanceState from '../../../../atoms/variables';
import { useEffect, useState } from 'react';
import { getInterconnectionCategories } from '../../../../../Settings/helpers/apiCalls';
import TableTip from '../../../../../Template/layoutComponents/Table/components/tip';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import NewInterconnectionModal from '../components/newInterconnectionModal';
import NewWoModal from '../../../../components/NewWoModal';
import './style.css';

const Interconnections = () => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const { instance } = useMsal();

  const { plantId } = useParams();

  const {
    setPendingApiCalls,
    setModalOpen,
    setModalConfirm,
    setModalContent,
    setModalTitle,
    setCleanOnCancel,
    setModalStyle,
  } = useTemplateState();
  const { interconnections, setInterconnections, orgs, setViewDetailsItem } = useMaintenanceState();
  const [notificationApi, contextHolder] = notification.useNotification();

  const [showWoButton, setShowWoButton] = useState(false);
  const [allCategories, setAllCategories] = useState([]);

  const [selectedInterconnections, setSelectedInterconnections] = useState([]);

  const [forceReload, setForceReload] = useState(false);

  useEffect(() => {
    triggerApiCalls();
  }, []);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) =>
        current.concat(['getInterconnectionAndUniques', 'getInterconnectionCategories']),
      );

      const responses = await Promise.all([
        getInterconnectionAndUniques(instance, { location: plantId, limit: 10 }),
        getInterconnectionCategories(instance),
      ]);
      const interconnectionsResponse = responses[0];
      interconnectionsResponse.items.map((item) => {
        item.from = item.properties.find((p) => p.key === 'origin').value;
        item.to = item.properties.find((p) => p.key === 'destiny').value;
      });
      setInterconnections(interconnectionsResponse);
      setAllCategories(responses[1]);
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
      console.log(err);
    } finally {
      setPendingApiCalls((current) =>
        current.filter(
          (item) =>
            item !== 'getInterconnectionAndUniques' && item !== 'getInterconnectionCategories',
        ),
      );
    }
  };

  return (
    <div className='interconnectionsContent'>
      {contextHolder}
      <div className='interconnectionsContentHeader'>
        <div className='pageTitle'>{t('interconnections_title')}</div>
        <div className='pageButtons'>
          {showWoButton && (
            <Button
              className='newItemButton'
              size='large'
              style={{
                borderColor: 'black',
                background: 'white',
                color: 'black',
              }}
              onClick={async () => {
                setModalTitle(t('new_wo'));
                setModalContent(
                  <NewWoModal
                    setForceReload={setForceReload}
                    preSelectedSubjectKind={[{ id: 'interconnection', label: '' }]}
                    selectedSubjects={selectedInterconnections}
                  />,
                );
                setModalStyle({ padding: '2rem', gap: '1rem', height: 'unset' });
                setCleanOnCancel(true);
                setModalConfirm(null);
                setModalOpen(true);
              }}
            >
              {t('generate_workorder')}
            </Button>
          )}
          <Button
            className='newItemButton'
            size='large'
            icon={<PlusOutlined />}
            style={{
              borderColor: '#1777FF',
              background: '#1777FF',
              color: 'white',
            }}
            onClick={async () => {
              setModalTitle(t('new_interconnection'));
              setModalContent(<NewInterconnectionModal setForceReload={setForceReload} />);
              setModalStyle({ padding: '2rem', gap: '1rem', height: 'unset' });

              setModalConfirm(null);
              setModalOpen(true);
            }}
          >
            {t('new_interconnection')}
          </Button>
        </div>
      </div>
      <Table
        handleSelect={(selected) => {
          setSelectedInterconnections(selected);
          if (selected.length > 0) {
            setShowWoButton(true);
          } else {
            setShowWoButton(false);
          }
        }}
        handleRowClick={(row) => {
          setViewDetailsItem({
            id: row.id,
            name: row.name,
            category: row.category,
            properties: row.properties.map((prop) => ({
              ...prop,
              disabled: prop.allowEdit ? false : prop.main,
            })),
          });
          navigate(`/maintenance/plants/${plantId}/interconnections/${row.id}`);
        }}
        apiCall={async (instance, queryParams) => {
          const response = await getInterconnectionAndUniques(instance, {
            location: plantId,
            ...queryParams,
          });

          response.items.map((item) => {
            item.from = item.properties.find((p) => p.key === 'origin').value;
            item.to = item.properties.find((p) => p.key === 'destiny').value;
          });
          return response;
        }}
        items={interconnections?.items}
        tableSettings={{
          totalItems: interconnections.totalItems ?? 0,
          columnsConfig: {
            category: {
              label: t('category'),
              replaceValue: (value) =>
                allCategories.find((cat) => cat._id === value || cat.id === value),
            },
            name: {
              label: t('name'),
            },
            from: {
              label: t('from'),
            },
            to: {
              label: t('to'),
            },
            status: {
              label: t('status'),
              customElement: (item) => <TableTip children={item.status ?? item} />,
            },
            pendingTasks: {
              label: t('pending_tasks'),
              customElement: (item) => (
                <div className='pendingTasksColumn'>
                  {item.pendingTasks.map((task) => (
                    <TableTip children={task.test[i18n.language ?? 'en'].title} />
                  ))}
                </div>
              ),
            },
          },
          sortable: ['category', 'name', 'status'],
          listFilter: ['category', 'status'],
          searchFilter: ['name'],
          uniqueValues: interconnections.uniqueValues,
        }}
        forceReload={forceReload}
        setForceReload={setForceReload}
      />
    </div>
  );
};

export default Interconnections;
