import { Button, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { CloseCircleOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';

import useSettingsState from '../../../../../atoms/variables';

import i18n from '../../../../../../_shared/translate/translate-config';
import SingleSelect from '../../../../../../Template/layoutComponents/SingleSelect';
import Checkbox from '../../../../../../Template/layoutComponents/Checkmark';
import {
  getCommissioningStages,
  updateCategory,
  updateInterconnectionCategory,
} from '../../../../../helpers/apiCalls';
import { useMsal } from '@azure/msal-react';

import useTemplateState from '../../../../../../Template/atoms/variables';

import addIcon from '../../../../../images/addIcon.svg';

import './style.css';
import { useNavigate, useParams } from 'react-router-dom';

const CommissioningTab = ({ initialPhase = 1, phasesLenght = 5 }) => {
  const { instance } = useMsal();
  const { t } = useTranslation();
  const { chosenType, categoryId } = useParams();
  const navigate = useNavigate();

  const [notificationApi, contextHolder] = notification.useNotification();

  const { setPendingApiCalls } = useTemplateState();

  const { viewDetailsCategory, setViewDetailsCategory, commonStages, setCommonStages } =
    useSettingsState();

  const [currentPhaseIndex, setCurrentPhaseIndex] = useState(0);
  const [currentPhase, setCurrentPhase] = useState({}); // L1. L2, L3, etc

  const [editing, setEditing] = useState(false);

  const defaultNewStage = {
    title: null,
    platform: null,
    plan: null,
  };

  useEffect(() => {
    if (viewDetailsCategory) {
      triggerApiCalls();
    } else {
      navigate(`/settings/asset-tree/manage/${chosenType}`);
    }
  }, []);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['getCommissioningStages']));
      const response = await getCommissioningStages(instance, categoryId);
      setCommonStages(response);
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'getCommissioningStages'));
    }
  };

  useEffect(() => {
    const stepNode = document.getElementById(
      `step-${currentPhaseIndex === phasesLenght ? 'operating' : currentPhaseIndex}`,
    );
    stepNode?.scrollIntoView();

    setCurrentPhase(viewDetailsCategory?.commissioning?.phases[currentPhaseIndex]);
  }, [currentPhaseIndex]);

  const disableNext = () => {
    return (
      !currentPhase?.stages ||
      currentPhase?.stages.length === 0 ||
      currentPhase?.stages?.some((stage) => !stage?.[i18n.language].plan) ||
      editing ||
      currentPhaseIndex === phasesLenght
    );
  };
  const disableSave = () => {
    return (
      !currentPhase?.stages ||
      currentPhase?.stages?.some((stage) => !stage?.[i18n.language].plan) ||
      currentPhase?.stages?.some((stage) =>
        stage?.[i18n.language].plan?.steps?.some(
          (step) =>
            step.items &&
            (step.items.length === 0 || step.items.some((item) => item.type === null)),
        ),
      )
    );
  };

  const disableBackCancel = () => {
    if (editing) {
      return false;
    } else {
      return currentPhaseIndex === 0;
    }
  };

  const addCriteria = () => {
    setCurrentPhase((current) => ({
      ...current,
      stages: (current?.stages || []).concat([
        { pt: defaultNewStage, en: defaultNewStage, es: defaultNewStage },
      ]),
    }));
  };

  const removeCriteria = (givenIndex) => {
    setCurrentPhase((current) => ({
      ...current,
      stages: current?.stages.filter((stage, index) => index != givenIndex),
    }));
  };

  const defaultNewItem = { type: null, mandatory: false };

  const concatNewItem = (stage, languageKey) => {
    return {
      ...stage?.[languageKey],
      plan: {
        ...stage?.[languageKey]?.plan,
        steps: stage?.[languageKey]?.plan?.steps?.map((step) => ({
          ...step,
          items: (step?.items || []).concat([{ ...defaultNewItem }]),
        })),
      },
    };
  };

  const concatFileUploadPossibleItems = (stage) => {
    const pt = stage['pt']?.plan?.steps.reduce((acc, step) => {
      acc = acc.concat(step.possibleItems.filter((item) => !acc.includes(item)) || []);
      return acc;
    }, []);

    const en = stage['en']?.plan?.steps.reduce((acc, step) => {
      acc = acc.concat(step.possibleItems.filter((item) => !acc.includes(item)) || []);
      return acc;
    }, []);

    const es = stage['es']?.plan?.steps.reduce((acc, step) => {
      acc = acc.concat(step.possibleItems.filter((item) => !acc.includes(item)) || []);
      return acc;
    }, []);

    return {
      pt: pt.map((item, index) => ({ id: index, label: item })),
      en: en.map((item, index) => ({ id: index, label: item })),
      es: es.map((item, index) => ({ id: index, label: item })),
    };
  };

  const updateFileUploadItem = (stage, languageKey, key, itemIndex, newValue) => {
    return {
      ...stage?.[languageKey],
      plan: {
        ...stage?.[languageKey]?.plan,
        steps: stage?.[languageKey]?.plan?.steps?.map((step) => ({
          ...step,
          items: step?.items.map((item, index) => {
            if (index === itemIndex) {
              let tmp = { ...item };
              tmp[key] = newValue;
              return tmp;
            } else return item;
          }),
        })),
      },
    };
  };

  return (
    <div className='commissioningContent'>
      {contextHolder}
      <div className='arrow-steps'>
        {[...Array(phasesLenght).keys()]?.map((step, index) => {
          const isActive = index <= currentPhaseIndex;
          return (
            <div
              id={`step-${index}`}
              key={`step-${index}`}
              className={`step ${currentPhaseIndex === index ? 'current' : isActive ? 'done' : ''}`}
            >
              <span>{`L${initialPhase + index}`}</span>
            </div>
          );
        })}
        <div
          key={'step-operating'}
          id={'step-operating'}
          className={`step ${currentPhaseIndex === phasesLenght ? 'done' : ''}`}
        >
          <span>{t('operating')}</span>
        </div>
      </div>
      <div className='commissioningPhaseContainer'>
        {currentPhaseIndex != phasesLenght ? (
          <div className='commissioningPhaseContainerTitle'>{t('acceptance_criteria')}</div>
        ) : (
          <div className='commissioningPhaseContainerTitle'>{t('operating_text')}</div>
        )}
        {currentPhase?.stages?.length > 0 && (
          <div className='commissioningPhaseStages'>
            {currentPhase?.stages?.map((stage, index) => {
              return (
                <div className='commissioningPhaseStage'>
                  <div className='formsInputContainer'>
                    <div className='formsInputLabel'>{t('criteria_type')}:</div>
                    <div className='formsInput'>
                      <SingleSelect
                        key={`single-select-criteria-type-${stage?.[i18n.language]?.key}`}
                        value={{ id: stage?.[i18n.language]?.key }}
                        options={
                          commonStages[i18n.language]
                            ?.map((op) => ({ ...op, id: op.key }))
                            .filter((option) => {
                              if (
                                !currentPhase?.stages?.some(
                                  (stage) => stage?.[i18n.language].key === option.key,
                                ) ||
                                stage?.[i18n.language]?.key === option.key
                              ) {
                                return option;
                              }
                            }) ?? []
                        }
                        onChange={(e) => {
                          setEditing(true);
                          setCurrentPhase((current) => ({
                            ...current,
                            stages: current?.stages?.map((stage, stageIndex) => {
                              if (stageIndex === index) {
                                return {
                                  pt: commonStages['pt'].find(
                                    (common) => common.key === e.target.value,
                                  ),
                                  en: commonStages['en'].find(
                                    (common) => common.key === e.target.value,
                                  ),
                                  es: commonStages['es'].find(
                                    (common) => common.key === e.target.value,
                                  ),
                                };
                              } else {
                                return stage;
                              }
                            }),
                          }));
                        }}
                      />
                    </div>
                  </div>

                  {stage?.[i18n.language]?.plan?.steps
                    ?.reduce((acc, step) => {
                      acc = acc.concat(
                        step?.items?.filter(
                          (item) => !acc.find((accItem) => accItem.key === item.key),
                        ) || [],
                      );
                      return acc;
                    }, [])
                    ?.map((item, itemIndex) => (
                      <div className='formsInputContainer'>
                        <div className='formsInputLabel' style={{ paddingLeft: '2rem' }}>
                          {t('document_type')}:
                        </div>
                        <div className='formsInput'>
                          <SingleSelect
                            key={`${currentPhaseIndex}-${
                              concatFileUploadPossibleItems(stage)[i18n.language].find(
                                (item) =>
                                  item.label ===
                                  stage?.[i18n.language]?.plan?.steps[0]?.items[itemIndex]?.type,
                              )?.label
                            }`}
                            value={{
                              id: concatFileUploadPossibleItems(stage)[i18n.language].find(
                                (item) =>
                                  item.label ===
                                  stage?.[i18n.language]?.plan?.steps[0]?.items[itemIndex]?.type,
                              )?.id,
                            }}
                            options={concatFileUploadPossibleItems(stage)[i18n.language]}
                            onChange={(e) => {
                              setEditing(true);
                              const possibleItems = concatFileUploadPossibleItems(stage);

                              setCurrentPhase((current) => ({
                                ...current,
                                stages: current.stages.map((stage, stageIndex) => {
                                  if (stageIndex === index) {
                                    return {
                                      pt: updateFileUploadItem(
                                        stage,
                                        'pt',
                                        'type',
                                        itemIndex,
                                        possibleItems.pt[e.target.value].label,
                                      ),
                                      en: updateFileUploadItem(
                                        stage,
                                        'en',
                                        'type',
                                        itemIndex,
                                        possibleItems.en[e.target.value].label,
                                      ),
                                      es: updateFileUploadItem(
                                        stage,
                                        'es',
                                        'type',
                                        itemIndex,
                                        possibleItems.es[e.target.value].label,
                                      ),
                                    };
                                  } else {
                                    return stage;
                                  }
                                }),
                              }));
                            }}
                          />
                          <div>
                            <Checkbox
                              label={t('required')}
                              checked={
                                stage?.[i18n.language]?.plan?.steps[0]?.items[itemIndex]?.mandatory
                              }
                              onChange={(e) => {
                                setEditing(true);
                                setCurrentPhase((current) => ({
                                  ...current,
                                  stages: current.stages.map((stage, stageIndex) => {
                                    if (stageIndex === index) {
                                      return {
                                        pt: updateFileUploadItem(
                                          stage,
                                          'pt',
                                          'mandatory',
                                          itemIndex,
                                          e.target.value,
                                        ),
                                        en: updateFileUploadItem(
                                          stage,
                                          'en',
                                          'mandatory',
                                          itemIndex,
                                          e.target.value,
                                        ),
                                        es: updateFileUploadItem(
                                          stage,
                                          'es',
                                          'mandatory',
                                          itemIndex,
                                          e.target.value,
                                        ),
                                      };
                                    } else {
                                      return stage;
                                    }
                                  }),
                                }));
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    ))}

                  {stage?.[i18n.language]?.plan?.steps.some((step) => step.items) && (
                    <div className='formsInputContainer'>
                      <div className='formsInputLabel' />
                      <div className='formsInput'>
                        <div className='addPropertyOptionContainer'>
                          <div
                            className='addPropertyOption'
                            onClick={() => {
                              setEditing(true);
                              setCurrentPhase((current) => ({
                                ...current,
                                stages: current.stages.map((stage, stageIndex) => {
                                  if (stageIndex === index) {
                                    return {
                                      pt: concatNewItem(stage, 'pt'),
                                      en: concatNewItem(stage, 'en'),
                                      es: concatNewItem(stage, 'es'),
                                    };
                                  } else {
                                    return stage;
                                  }
                                }),
                              }));
                            }}
                          >
                            <div className='addPropertyIcon'>
                              <img src={addIcon} />
                            </div>
                            <div className='addPropertyText'>{t('new_item')}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <Button
                    icon={<DeleteOutlined />}
                    id='cancel'
                    size='large'
                    style={{
                      borderColor: 'black',
                      background: 'white',
                      color: 'black',
                    }}
                    onClick={() => {
                      setEditing(true);
                      removeCriteria(index);
                    }}
                  >
                    {t('remove')}
                  </Button>
                </div>
              );
            })}
          </div>
        )}
        {currentPhaseIndex != phasesLenght ? (
          <div className='addStageButton'>
            <Button
              icon={<PlusOutlined />}
              style={{
                background: 'black',
                color: 'white',
                border: 'black',
              }}
              size='large'
              onClick={() => {
                setEditing(true);
                addCriteria();
              }}
            >
              {t('add_criteria')}
            </Button>
          </div>
        ) : (
          <div className='commissioningPhaseText'>{t('operating_description')}</div>
        )}
      </div>

      <div className='previousnextButtonsContainer'>
        <Button
          id='cancel'
          size='large'
          style={{
            borderColor: 'black',
            background: 'white',
            color: 'black',
            opacity: disableBackCancel() ? 0.5 : 1,
          }}
          icon={editing ? <CloseCircleOutlined /> : null}
          disabled={disableBackCancel()}
          onClick={() => {
            if (editing) {
              setCurrentPhase(viewDetailsCategory?.commissioning?.phases[currentPhaseIndex]);
              setEditing(false);
            } else {
              setCurrentPhaseIndex((current) => (current > 0 ? current - 1 : current));
            }
          }}
        >
          {editing ? t('cancel_changes') : t('back')}
        </Button>
        {editing && (
          <Button
            size='large'
            style={{
              borderColor: 'black',
              background: 'black',
              color: 'white',
              opacity: disableSave() ? 0.5 : 1,
            }}
            disabled={disableSave()}
            onClick={async () => {
              try {
                let tmp = { ...viewDetailsCategory };

                tmp = {
                  ...tmp,
                  commissioning: {
                    ...tmp.commissioning,
                    phases: tmp.commissioning.phases?.map((phase, index) => {
                      if (index === currentPhaseIndex) {
                        return { ...currentPhase, name: `L${currentPhaseIndex + initialPhase}` };
                      } else {
                        return phase;
                      }
                    }),
                  },
                };

                if (tmp?.commissioning?.phases?.length <= currentPhaseIndex)
                  tmp.commissioning.phases = tmp?.commissioning?.phases.concat([
                    { ...currentPhase, name: `L${currentPhaseIndex + initialPhase}` },
                  ]);

                // SEND ITEM TO BFF (UPDATE)
                setPendingApiCalls((current) => current.concat(['updateCategory']));

                if (chosenType === 'assets') {
                  await updateCategory(instance, tmp);
                } else {
                  await updateInterconnectionCategory(instance, tmp);
                }
                setViewDetailsCategory(tmp);
                setEditing(false);
              } catch (err) {
                notificationApi.error({
                  message: 'Não foi possível recuperar todas as informações necessárias',
                  description: err?.response?.data?.message
                    ? `${err?.response?.data?.message} (${err?.response?.status})`
                    : err?.message,
                  duration: 5,
                });
              } finally {
                setPendingApiCalls((current) =>
                  current.filter((item) => item !== 'updateCategory'),
                );
              }
            }}
          >
            {t('save')}
          </Button>
        )}
        <Button
          disabled={disableNext()}
          size='large'
          style={{
            borderColor: '#1777FF',
            background: '#1777FF',
            color: 'white',
            opacity: disableNext() ? 0.5 : 1,
          }}
          onClick={() =>
            setCurrentPhaseIndex((current) => (current < phasesLenght ? current + 1 : current))
          }
        >
          {t('next')}
        </Button>
      </div>
    </div>
  );
};

export default CommissioningTab;
