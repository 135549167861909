import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import Tabs from '../../../../Template/layoutComponents/Tabs';

import HistoryTab from '../../../../Template/layoutComponents/HistoryTab';
import GeneralTab from '../../../../Template/layoutComponents/GeneralTab';
import { updateUserGroup } from '../../../helpers/apiCalls';
import { notification } from 'antd';
import { useMsal } from '@azure/msal-react';
import useTemplateState from '../../../../Template/atoms/variables';
import useSettingsState from '../../../atoms/variables';
import { getUserGroups } from '../../../helpers/apiCalls';
import AllUsersTab from './components/allUsers';

const UserGroupDetails = () => {
  const { t } = useTranslation();
  const [notificationApi, contextHolder] = notification.useNotification();
  const { instance } = useMsal();
  const { categoryId } = useParams();

  const { setPendingApiCalls } = useTemplateState();
  const { viewDetailsCategory, setViewDetailsCategory, setUserGroups } = useSettingsState();

  useEffect(() => {
    if (!viewDetailsCategory || !viewDetailsCategory.access_level) {
      triggerApiCalls();
    }
  }, []);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['getUserGroups']));

      const response = await getUserGroups(instance);
      setUserGroups(response);
      setViewDetailsCategory(response?.find((u) => u.id === categoryId || u._id === categoryId));
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'getUserGroups'));
    }
  };

  return (
    <div id='assetDetails'>
      {contextHolder}
      <Tabs
        chosenTabStyleOverride={{ paddingTop: 0 }}
        tabs={[
          {
            label: t('general_tab'),
            content: (
              <GeneralTab detailsLink={'/settings/user-groups'} updateItem={updateUserGroup} />
            ),
            disabled: false,
          },
          {
            label: t('users'),
            content: <AllUsersTab />,
            disabled: false,
          },
          {
            label: t('history_tab'),
            content: <HistoryTab id={categoryId} />,
            disabled: false,
          },
        ].filter((tab) => tab)}
      />
    </div>
  );
};

export default UserGroupDetails;
