import { useEffect, useState } from 'react';
import { Button } from 'antd';
import TripleColumnTemplate from '../../../../Template/layoutComponents/TripleColumn';

import { PlusOutlined, CloseCircleOutlined, AppstoreAddOutlined } from '@ant-design/icons';

import Input from '../../../../Template/layoutComponents/Input';
import Select from '../../../../Template/layoutComponents/SingleSelect';
import Forms from '../../../../Template/layoutComponents/Forms';

import search from '../../../images/search.svg';

import Tree from '../../../components/Tree';
import Checkbox from '../../../../Template/layoutComponents/Checkmark';
import Tooltip from '../../../../Template/layoutComponents/Tooltip';
import useSettingsState from '../../../atoms/variables';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useGlobalState from '../../../../Template/atoms/variables';
import { useTranslation } from 'react-i18next';
import { sleep } from '../../../../helpers/misc';
import CustomOrderByButton from '../../../../Template/layoutComponents/OrderButton';
import {
  getAllCategories,
  getInstrumentCategories,
  getInterconnectionCategories,
  newCategory,
  newInstumentCategory,
  newInterconnectionCategory,
  updateCategory,
  updateInstrumentCategory,
  updateInterconnectionCategory,
} from '../../../helpers/apiCalls';
import { useMsal } from '@azure/msal-react';
import { notification } from 'antd';
import moment from 'moment';

import DoubleColumnTemplate from '../../../../Template/layoutComponents/DoubleColumn';

import viewDetails from '../../../../Maintenance/images/viewDetails.svg';

import './style.css';
import SecondaryCategories from '../../../components/SecondaryCategories';

const AssetCategories = ({}) => {
  const navigate = useNavigate();
  const { instance } = useMsal();

  const { t } = useTranslation();

  const location = useLocation();
  const { chosenType } = useParams();
  const { chosenPositions, setViewDetailsCategory, setViewDetailsCategories } = useSettingsState();
  const [notificationApi, contextHolder] = notification.useNotification();

  const { setPendingApiCalls, companyInfo } = useGlobalState();

  useEffect(() => {
    if (!['assets', 'positions', 'instruments', 'interconnections'].includes(chosenType))
      navigate('/settings/asset-tree/manage');
    else {
      triggerApiCalls();
    }
  }, []);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['getAllCategories']));
      let response;
      if (chosenType === 'instruments') {
        response = await getInstrumentCategories(instance);
      } else if (chosenType === 'interconnections') {
        response = await getInterconnectionCategories(instance);
      } else {
        response = await getAllCategories(instance);
      }
      setCategoriesForKind(response);
    } catch (err) {
      // API fail notification
      notificationApi.error({
        message: 'Falha ao carregar categorias existentes',
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'getAllCategories'));
    }
  };

  const [chosenPosition, setChosenPosition] = useState(location.state?.selectedNode || null);
  const [chosenCategory, setChosenCategory] = useState(null);

  const [searchString, setSearchString] = useState(null);
  const [asc, setAsc] = useState(true);

  const [creatingCategory, setCreatingCategory] = useState(false);
  const [editingCategory, setEditingCategory] = useState(false);

  const [categoriesForKind, setCategoriesForKind] = useState([]);

  const [showNotification, setShowNotification] = useState(false);
  const [notificationContent, setNotificationContent] = useState(false);

  const [certificationInterval, setCertificationInterval] = useState(365);
  const [certificationScale, setCertificationScale] = useState(1);

  const labelMapping = {
    plant: 'plant',
    area: 'area',
    'sub-area': 'sub_area',
    line: 'line',
    machine: 'machine',
    assembly: 'assembly',
    equipment: 'equipment',
    component: 'component',
  };

  const translateNodes = (nodes) => {
    return nodes?.map((node) => {
      const translationKey = labelMapping[node.type] || node.label.toLowerCase();
      return {
        ...node,
        label: t(translationKey),
      };
    });
  };

  const defaultCategory = {
    new: true,
    label: t('new_category'),
    type: chosenPosition?.type,
    properties: [
      {
        label: t('name'),
        type: 'text',
        value: t('new_category'),
        main: true,
        required: true,
        disabled: false,
        shouldHoldPlace: true,
        key: 'name',
      },
      {
        label: t('abbreviation'),
        type: 'text',
        value: '',
        main: true,
        required: true,
        disabled: false,
        maxLength: 4,
        key: 'abbrev',
      },
      chosenType === 'positions' &&
        chosenPosition?.type === 'plant' && {
          label: t('icon'),
          type: 'singleSelect',
          value: '',
          options: [
            { id: 'datacenter', label: t('datacenter') },
            { id: 'vessel', label: t('vessel') },
            { id: 'factory', label: t('factory') },
            { id: 'system', label: t('system') },
          ],
          main: true,
          required: true,
          disabled: false,
          key: 'icon',
        },
    ].filter((prop) => prop),
    commissioning: { phases: [] },
    commissionable: false,
    certifiable: chosenType === 'instruments' ? false : undefined,
    certification_cycle: chosenType === 'instruments' ? 365 : undefined,
  };

  const disableCreateSave = () => {
    if (creatingCategory) {
      return (
        categoriesForKind.some((cat) =>
          cat.properties
            .filter((prop) => prop.main == true)
            .some((prop) =>
              chosenCategory?.properties
                .filter((currentProp) => currentProp.main == true)
                .some(
                  (currentProp) =>
                    (prop.key == currentProp.key && prop.value == currentProp.value) ||
                    currentProp.value == '',
                ),
            ),
        ) ||
        chosenCategory.properties.some(
          (prop) =>
            !prop.type ||
            prop.label == '' ||
            prop.label == t('new_property') ||
            prop.options?.some((opt) => opt?.label == '') ||
            (prop.type.toLowerCase().includes('select') && prop.options?.length === 0),
        )
      );
    }
    if (editingCategory)
      return chosenCategory.properties.some(
        (prop) =>
          !prop.type ||
          prop.label == '' ||
          prop.label == t('new_property') ||
          prop.options?.some((opt) => opt?.label == '') ||
          (prop.type.toLowerCase().includes('select') && prop.options?.length === 0),
      );

    return true;
  };

  useEffect(() => {
    setChosenCategory(null);
    setCreatingCategory(false);
    setEditingCategory(false);
  }, [t]);

  useEffect(() => {
    setCertificationScale(1);
    const alreadyExists = categoriesForKind.some((cat) =>
      cat.properties
        .filter((prop) => prop.main == true)
        .some((prop) =>
          chosenCategory?.properties
            .filter((currentProp) => currentProp.main == true)
            .some((currentProp) => {
              if (
                prop.key == currentProp.key &&
                prop.value !== '' &&
                prop.value == currentProp.value
              ) {
                setNotificationContent(currentProp.label);
                return true;
              }
            }),
        ),
    );

    setShowNotification(alreadyExists);
  }, [chosenCategory]);

  const listColumn = (
    <div className='columnContainer' style={{ padding: 0 }}>
      <div className='columnHeader' style={{ padding: '2rem 2rem 0 2rem' }}>
        <div className='columnTitle'>{t('categories')}</div>
        <Button
          disabled={
            (chosenType !== 'instruments' &&
              chosenType !== 'interconnections' &&
              chosenPosition === null) ||
            creatingCategory ||
            editingCategory
          }
          icon={<PlusOutlined />}
          size='large'
          style={{
            background:
              (chosenType !== 'instruments' &&
                chosenType !== 'interconnections' &&
                chosenPosition === null) ||
              creatingCategory ||
              editingCategory
                ? 'grey'
                : 'black',
            color: 'white',
          }}
          onClick={() => {
            setEditingCategory(false);
            setCreatingCategory(true);
            setChosenCategory(defaultCategory);
          }}
        >
          {t('new_category')}
        </Button>
      </div>
      <div className='searchControls' style={{ padding: '1.5rem 2rem 0 2rem' }}>
        <Input
          value={searchString ?? ''}
          onChange={(event) => {
            setSearchString(event.target.value ?? null);
          }}
          style={{ maxWidth: '80%' }}
          placeholder={t('search')}
          icon={<img src={search} />}
        />
        <CustomOrderByButton
          onClick={() => {
            setAsc((current) => !current);
          }}
        />
      </div>
      <div className='categoryList'>
        {categoriesForKind
          .filter((category) => {
            return category.type == chosenPosition?.type;
          })
          .filter((category) => {
            if (searchString)
              return category.label.toLowerCase().includes(searchString?.toLowerCase());
            else return true;
          })
          .concat(creatingCategory ? [defaultCategory] : [])
          .sort((a, b) =>
            a.new == true ? -1 : asc ? (a.label < b.label ? -1 : 1) : a.label < b.label ? 1 : -1,
          )
          .map((category, index) => {
            if (category.new) {
              return (
                <div
                  className={`categoryListItem ${chosenCategory?.id == category.id ? 'selected' : ''} creating`}
                >
                  {category.label}
                </div>
              );
            } else
              return (
                <>
                  {index === 0 && <div className='categoryListDivider'></div>}
                  <div
                    className={`categoryListItem ${chosenCategory?.id == category.id ? 'selected' : ''} ${creatingCategory || editingCategory ? 'disabled' : ''}`}
                    onClick={() => {
                      if (!creatingCategory && !editingCategory) {
                        setChosenCategory(category);
                        setSearchString(null);
                      }
                    }}
                  >
                    {category.label}
                  </div>
                  <div className='categoryListDivider'></div>
                </>
              );
          })}
      </div>
    </div>
  );

  const editColumn = (
    <div
      className='columnContainer'
      style={{
        padding: 0,
        justifyContent: 'space-between',
        maxHeight: 'calc(100vh - 6rem)',
        overflow: 'auto',
      }}
    >
      {chosenCategory && (
        <div className='columnHeader' style={{ padding: '2rem 2rem 0 2rem' }}>
          <div className='columnTitle'>
            {chosenCategory?.label?.length > 0 ? chosenCategory.label : t('new_category')}
          </div>
          {chosenCategory && !chosenCategory?.new && !creatingCategory && (
            <div
              className={`viewDetailsContainer ${editingCategory ? 'disabled' : ''}`}
              onClick={() => {
                if (!editingCategory) {
                  setViewDetailsCategory(chosenCategory);
                  setViewDetailsCategories(categoriesForKind);
                  navigate(`/settings/asset-tree/manage/${chosenType}/${chosenCategory.id}`);
                }
              }}
            >
              <div className='viewDetailsIcon'>
                <img src={viewDetails} />
              </div>
              <div className='viewDetailsText'>{t('view_details')}</div>
            </div>
          )}
        </div>
      )}
      <div className='categoryPropertiesContainer' style={{ padding: '1.5rem 2rem 5rem 2rem' }}>
        <Forms
          inputs={chosenCategory?.properties.filter((prop) => prop.main)}
          onInputBlur={() => {
            if (showNotification) {
              notificationApi.info({
                message: `Valores repetidos`,
                description: `Valor do campo '${notificationContent}' já cadastrado. Use outro valor.`,
                duration: 5,
              });
            }
          }}
          onInputChange={(event, inputIndex) => {
            if (!creatingCategory) {
              setEditingCategory(true);
            }

            setChosenCategory((current) => {
              if (current) {
                return {
                  ...current,
                  properties: current.properties.map((item, index) => {
                    if (index == inputIndex) {
                      item.value = item.type.toLowerCase().includes('select')
                        ? { id: event.target.value, label: '' }
                        : event.target.value;
                    }
                    return item;
                  }),
                  label: current.properties[0].value,
                };
              }
            });
          }}
        />
      </div>
      {chosenCategory && companyInfo?.commissioning && (
        <div
          className='categoryCommissioning'
          style={
            chosenType == 'assets' || chosenType === 'interconnections' ? {} : { borderTop: 'none' }
          }
        >
          {(chosenType == 'assets' || chosenType === 'interconnections') && (
            <div className='categoryCommissioningTitleContainer'>
              <div className='categoryCommissioningTitle'>Commissioning</div>
              <Tooltip />
            </div>
          )}
          {(chosenType == 'assets' || chosenType === 'interconnections') && (
            <div className='categoryCommissioningCheck'>
              <Checkbox
                label={t('commissionable_category')}
                checked={chosenCategory.commissionable}
                onChange={(e) => {
                  setChosenCategory((current) => {
                    if (!creatingCategory) {
                      setEditingCategory(true);
                    }

                    return { ...current, commissionable: e.target.value };
                  });
                }}
              />
            </div>
          )}
        </div>
      )}
      {chosenCategory && chosenType === 'instruments' && (
        <div className='categoryCommissioning'>
          <div className='categoryCommissioningTitleContainer'>
            <div className='categoryCommissioningTitle'> {t('certifiable_category')}</div>
            <Tooltip />
          </div>

          <div className='formsBody'>
            <div className='formsInputContainer'>
              <div className='formsInputLabel'>{t('certifiable_text')}</div>
              <div className='formsInput'>
                <Checkbox
                  key={`certificable-0-${chosenCategory.id}-${chosenCategory.certifiable}`}
                  label=''
                  checked={chosenCategory?.certifiable}
                  onChange={(e) => {
                    setChosenCategory((current) => {
                      if (!creatingCategory) {
                        setEditingCategory(true);
                      }
                      if (!e.target.value) {
                        setCertificationInterval(365);
                        setCertificationScale(1);
                      }
                      return {
                        ...current,
                        certifiable: e.target.value,
                        certification_cycle: 365,
                      };
                    });
                  }}
                />
              </div>
            </div>
            <div className='formsInputContainer'>
              <div className='formsInputLabel'>{t('certifiable_interval')}:</div>
              <div className='formsInput'>
                <Input
                  key={`certificable-1-${chosenCategory.id}-${chosenCategory.certifiable}`}
                  disabled={!chosenCategory.certifiable}
                  value={
                    chosenCategory?.certification_cycle
                      ? `${chosenCategory.certification_cycle}`
                      : `${certificationInterval}`
                  }
                  type={'number'}
                  onChange={(e) => {
                    setChosenCategory((current) => {
                      if (!creatingCategory) {
                        setEditingCategory(true);
                      }
                      setCertificationInterval(e.target.value ? parseInt(e.target.value) : 0);

                      return {
                        ...current,
                        certification_cycle: e.target.value
                          ? parseInt(e.target.value) * certificationScale
                          : 0 * certificationScale,
                      };
                    });
                  }}
                />
              </div>
            </div>
            <div className='formsInputContainer'>
              <div className='formsInputLabel'>{t('certifiable_scale')}:</div>
              <div className='formsInput'>
                <Select
                  key={`certificable-2-${chosenCategory.id}-${chosenCategory.certifiable}`}
                  disabled={!chosenCategory.certifiable}
                  value={{ id: `${certificationScale}` }}
                  options={[
                    { id: '1', label: t('days') },
                    { id: '30', label: t('months') },
                    { id: '365', label: t('years') },
                  ]}
                  onChange={(e) => {
                    setChosenCategory((current) => {
                      if (!creatingCategory) {
                        setEditingCategory(true);
                      }

                      setCertificationScale(parseInt(e.target.value));

                      return {
                        ...current,
                        certification_cycle: certificationInterval * parseInt(e.target.value),
                      };
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <SecondaryCategories
        chosenCategory={chosenCategory}
        setChosenCategory={setChosenCategory}
        setEditingCategory={setEditingCategory}
        companyInfo={companyInfo}
      />

      {chosenCategory && (
        <div className='newPropertyButtonContainer'>
          <div className='newPropertyButton'>
            <Button
              disabled={false}
              icon={<PlusOutlined />}
              size='large'
              style={{
                borderColor: '#1777FF',
                background: 'white',
                color: '#1777FF',
              }}
              onClick={() => {
                setEditingCategory(true);
                setChosenCategory((current) => {
                  return {
                    ...current,
                    properties: [...current.properties].concat([
                      {
                        label: t('new_property'),
                        type: null,
                        value: '',
                        main: false,
                        required: false,
                        disabled: false,
                        key: `newproperty-${moment()}`,
                      },
                    ]),
                  };
                });
              }}
            >
              {t('new_property')}
            </Button>
          </div>
        </div>
      )}
      <div className='manageCategoriesButtonsContainer'>
        {(creatingCategory || editingCategory) && (
          <Button
            id='cancel'
            size='large'
            icon={<CloseCircleOutlined />}
            style={{
              borderColor: 'black',
              background: 'white',
              color: 'black',
            }}
            onClick={() => {
              setEditingCategory(false);
              setCreatingCategory(false);
              setChosenCategory(null);
            }}
          >
            {t('cancel_changes')}
          </Button>
        )}
        {chosenCategory && (
          <Button
            id='create'
            className={disableCreateSave() ? 'disabled' : ''}
            size='large'
            disabled={disableCreateSave()}
            style={{
              borderColor: '#1777FF',
              background: '#1777FF',
              color: 'white',
            }}
            icon={<AppstoreAddOutlined />}
            onClick={async () => {
              if (creatingCategory) {
                // SEND ITEM TO BFF (CREATE)
                try {
                  setPendingApiCalls((current) => current.concat(['newCategory']));
                  let mongoItem;
                  if (chosenType === 'instruments') {
                    mongoItem = await newInstumentCategory(instance, chosenCategory);
                  } else if (chosenType === 'interconnections') {
                    mongoItem = await newInterconnectionCategory(instance, chosenCategory);
                  } else {
                    mongoItem = await newCategory(instance, chosenCategory);
                  }

                  setCategoriesForKind((current) => {
                    return current.concat([
                      {
                        ...chosenCategory,
                        properties: chosenCategory.properties.map((prop, index) => {
                          if (prop.main == true) prop.disabled = true;
                          return prop;
                        }),
                        new: false,
                        id: mongoItem._id ?? mongoItem,
                      },
                    ]);
                  });
                } catch (err) {
                  // API fail notification
                  notificationApi.error({
                    message: 'Falha ao criar nova categoria',
                    description: err?.response?.data?.message
                      ? `${err?.response?.data?.message} (${err?.response?.status})`
                      : err?.message,
                    duration: 5,
                  });
                } finally {
                  setChosenCategory(null);
                  setPendingApiCalls((current) => current.filter((item) => item !== 'newCategory'));
                }
              } else {
                setCategoriesForKind((current) => {
                  return current
                    .filter((cat) => cat.id != chosenCategory.id)
                    .concat([{ ...chosenCategory }]);
                });

                // SEND ITEM TO BFF (UPDATE)
                try {
                  setPendingApiCalls((current) => current.concat(['updateCategory']));

                  if (chosenType === 'instruments') {
                    await updateInstrumentCategory(instance, chosenCategory);
                  } else if (chosenType === 'interconnections') {
                    await updateInterconnectionCategory(instance, chosenCategory);
                  } else {
                    await updateCategory(instance, chosenCategory);
                  }
                } catch (err) {
                  // API fail notification
                  notificationApi.error({
                    message: 'Falha ao atualizar categoria existente',
                    description: err?.response?.data?.message
                      ? `${err?.response?.data?.message} (${err?.response?.status})`
                      : err?.message,
                    duration: 5,
                  });
                } finally {
                  setPendingApiCalls((current) =>
                    current.filter((item) => item !== 'updateCategory'),
                  );
                }
              }
              setCreatingCategory(false);
              setEditingCategory(false);
            }}
          >
            {creatingCategory ? t('create_item_settings') : t('save_item_settings')}
          </Button>
        )}
      </div>
    </div>
  );

  return (
    <div id='assetCategories'>
      {contextHolder}

      {chosenType === 'instruments' || chosenType === 'interconnections' ? (
        <DoubleColumnTemplate
          columnColors={['linear-gradient(180deg, #FDFDFD 0%, #F7F7F7 100%)', '#FFFFFF']}
          firstColumnContent={listColumn}
          secondColumnContent={editColumn}
        />
      ) : (
        <TripleColumnTemplate
          firstColumnContent={
            <div className='columnContainer'>
              <div className='columnHeader' style={{ minHeight: '3rem', flexBasis: 'none' }}>
                <div className='columnTitle'>
                  {chosenType === 'positions' ? t('my_positions') : t('my_assets')}
                </div>
              </div>
              <Tree
                disabled={creatingCategory || editingCategory}
                treeNodes={
                  translateNodes(
                    chosenPositions?.filter((node) => chosenType.includes(node.kind)),
                  ) ?? []
                }
                maxNodeWidth='90%'
                nodeType={chosenType}
                chosenPositions={[chosenPosition]}
                setChosenPosition={setChosenPosition}
                setChosenCategory={setChosenCategory}
                setSearchString={setSearchString}
              />
            </div>
          }
          secondColumnContent={listColumn}
          thirdColumnContent={editColumn}
        />
      )}
    </div>
  );
};

export default AssetCategories;
