import { useMsal } from '@azure/msal-react';
import Table from '../../../../../Template/layoutComponents/Table';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getUsers, updateUser } from '../../../../helpers/apiCalls';
import useTemplateState from '../../../../../Template/atoms/variables';
import { Button, notification } from 'antd';
import useMaintenanceState from '../../../../../Maintenance/atoms/variables';
import { getOrgs } from '../../../../../Maintenance/helpers/apiCalls';

import { PlusOutlined } from '@ant-design/icons';

import './style.css';

const AddUsersModal = ({ setReload }) => {
  const { t } = useTranslation();
  const { categoryId } = useParams();
  const { instance } = useMsal();

  const { setPendingApiCalls, setModalContent, setModalOpen } = useTemplateState();
  const { orgs, setOrgs } = useMaintenanceState();

  const [notificationApi, contextHolder] = notification.useNotification();

  const [selectedUsers, setSelectedUsers] = useState([]);

  const [users, setUsers] = useState();

  useEffect(() => {
    triggerApiCalls();
  }, []);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['getUsers', 'getOrgs']));

      const responses = await Promise.all([getUsers(instance, { limit: 10 }), getOrgs(instance)]);

      setUsers(responses[0]);
      setOrgs(responses[1]);
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
      console.log(err);
    } finally {
      setPendingApiCalls((current) =>
        current.filter((item) => item !== 'getUsers' && item !== 'getOrgs'),
      );
    }
  };

  return (
    <div className='addUsersModal'>
      {contextHolder}
      <div className='modalContentHeader'>
        <div className='pageButtons'>
          <Button
            className='newItemButton'
            size='large'
            disabled={selectedUsers.length === 0}
            icon={<PlusOutlined />}
            style={{
              borderColor: '#1777FF',
              background: '#1777FF',
              color: 'white',
              opacity: selectedUsers.length > 0 ? 1 : 0.5,
            }}
            onClick={async () => {
              try {
                setPendingApiCalls((current) => current.concat(['updateUser']));
                const promises = selectedUsers.map((u) => {
                  return updateUser(instance, {
                    ...u,
                    user_group: [...u.user_group, categoryId],
                    properties: u.properties.map((prop) => {
                      if (prop.key === 'user_group') {
                        return { ...prop, value: [...prop.value, { id: categoryId }] };
                      } else return prop;
                    }),
                  });
                });
                await Promise.all(promises);

                setModalContent(null);
                setModalOpen(false);
                setReload(true);
              } catch (err) {
                notificationApi.error({
                  message: t('database_communication_failure'),
                  description: err?.response?.data?.message
                    ? `${err?.response?.data?.message} (${err?.response?.status})`
                    : err?.message,
                  duration: 5,
                });
              } finally {
                setPendingApiCalls((current) => current.filter((item) => item !== 'updateUser'));
              }
            }}
          >
            {t('add')}
          </Button>
        </div>
      </div>
      <Table
        handleSelect={(selected) => {
          setSelectedUsers(selected);
        }}
        handleRowClick={null}
        apiCall={getUsers}
        items={users?.items}
        tableSettings={{
          columnsConfig: {
            user_id: {
              label: t('table.fullName'),
              customElement: (item) => {
                const accountInfo = instance.getAccountByHomeId(item.user_id)?.idTokenClaims;
                return accountInfo
                  ? (accountInfo?.name ?? `${accountInfo?.given_name} ${accountInfo?.family_name}`)
                  : '-';
              },
            },
            email: { label: t('email') },
            organization: {
              label: t('location'),
              customElement: (item) => {
                return orgs.find((o) => o.id === item.organization)?.country ?? '-';
              },
            },
            preferences: {
              label: t('language'),
              customElement: (item) => {
                return item?.preferences?.language?.toUpperCase();
              },
            },
          },
          sortable: ['email'],
          searchFilter: ['email'],
          listFilter: [],
          uniqueValues: users?.uniqueValues,
          totalItems: users?.totalItems,
        }}
      />
    </div>
  );
};

export default AddUsersModal;
