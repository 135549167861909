export function getNewItem(t, possibleChild, chosenLevel) {

    return {
        id: `new-${possibleChild?.type?.toLowerCase()}-${chosenLevel?.id}-${chosenLevel?.children.length}`,
        name: t(`new_${possibleChild?.type?.replace('-', '_')}`),
        type: `${possibleChild?.type}`,
        properties: [
            {
                label: t('name'),
                type: 'text',
                value: t(`new_${possibleChild?.type?.replace('-', '_')}`),
                main: true,
                required: true,
                key: "name"
            },
            {
                label: t('category'),
                type: 'singleSelect',
                value: null,
                options: [],
                main: true,
                allowEdit: true,
                required: true,
                key: "category"
            },
            {
                label: t('organization'),
                type: 'singleSelect',
                value: null,
                options: [],
                main: true,
                allowEdit: true,
                required: true,
                key: "organization"
            },
            {
                label: t('department'),
                type: 'singleSelect',
                value: null,
                options: [],
                main: true,
                allowEdit: true,
                required: true,
                key: "department"
            },
            possibleChild?.kind == 'asset' && {
                label: t('cost_center'),
                type: 'singleSelect',
                value: null,
                options: [],
                main: true,
                allowEdit: true,
                required: true,
                key: "cost_center"
            },
            possibleChild?.kind == 'asset' && {
                label: t('system'),
                type: 'multiSelect',
                value: null,
                options: [],
                main: true,
                allowEdit: true,
                required: false,
                key: "system"
            },
            possibleChild?.kind == 'asset' && {
                label: t('purchase_date'),
                type: 'date',
                value: null,
                main: true,
                required: true,
                key: "purchase_date"
            },
            {
                label: t('installation_date'),
                type: 'date',
                value: null,
                main: true,
                required: true,
                key: "installation_date"
            },
            possibleChild?.kind == 'asset' && {
                label: t('manufacturer'),
                type: 'singleSelect',
                options: [],
                value: null,
                main: true,
                required: true,
                key: "manufacturer"
            },
            possibleChild?.kind == 'asset' && {
                label: t('model_number'),
                type: 'text',
                value: null,
                main: true,
                required: true,
                key: "model_number"
            },
            possibleChild?.kind == 'asset' && {
                label: t('serial_number'),
                type: 'text',
                value: null,
                main: true,
                required: true,
                key: "serial_number"
            },
            {
                label: t('out_of_service'),
                type: 'bool',
                value: false,
                main: true,
                allowEdit: true,
                required: true,
                key: "out_of_service"
            },
        ].filter((prop) => prop),
        commissioning: false,
        children: [],
    }
}

export function getNewPlant(t) {
    return {
        id: 'newPlant',
        name: t('new_plant'),
        icon: 'factory',
        category: null,
        type: 'plant',
        properties: [
            {
                label: t('name'),
                type: 'text',
                value: () => t('new_plant'),
                main: true,
                required: true,
                key: 'name',
            },
            {
                label: t('category'),
                type: 'singleSelect',
                value: null,
                options: [],
                main: true,
                allowEdit: true,
                required: true,
                key: 'category',
            },
            {
                label: t('organization'),
                type: 'singleSelect',
                value: null,
                options: [],
                main: true,
                allowEdit: true,
                required: true,
                key: 'organization',
            },
            {
                label: t('department'),
                type: 'singleSelect',
                value: null,
                options: [],
                main: true,
                allowEdit: true,
                required: true,
                key: 'department',
            },
            {
                label: t('installation_date'),
                type: 'date',
                value: null,
                main: true,
                required: true,
                key: 'installation_date',
            },
            {
                label: t('out_of_service'),
                type: 'bool',
                value: false,
                main: true,
                allowEdit: true,
                required: true,
                key: 'out_of_service',
            },
        ],
        children: [],
    }
}

export function getNewSystem(t) {
    return {
        id: 'newSystem',
        name: t('new_system'),
        properties: [
            {
                label: t('name'),
                type: 'text',
                value: t('new_system'),
                main: true,
                required: true,
                key: 'name',
            },
            {
                label: t('organization'),
                type: 'singleSelect',
                options: [],
                value: null,
                main: true,
                required: true,
                key: 'organization',
            },
            {
                label: t('cost_center'),
                type: 'singleSelect',
                options: [],
                value: null,
                main: true,
                required: true,
                key: 'cost_center',
            },
            {
                label: t('department'),
                type: 'singleSelect',
                options: [],
                value: null,
                main: true,
                required: true,
                key: 'department',
            },
        ],
        parent: null,
        children: [],
    }
}