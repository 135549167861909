import axios from 'axios';
import { message } from 'antd';
import { protectedResourcesScopes } from '../../auth/config';
import { acquireToken } from '../../auth/msal';
import apiErrorResponseValidation from '../utils/apiErrorResponseValidation';

export const checkPendingTasks = async (instance, equipmentList) => {
  try {
    const accessToken = await acquireToken(instance, protectedResourcesScopes.core);

    const { data } = await axios.post(
      `${process.env.REACT_APP_BFF_API}/commissioning/workOrder/tasks/pending`,
      equipmentList,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
    return data;
  } catch (e) {
    console.log(e);
    message.error(apiErrorResponseValidation(e, 'Erro ao enviar arquivo'));
    return;
  }
};
