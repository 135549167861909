import { Button, notification } from 'antd';

import DoubleColumnTemplate from '../../../../../Template/layoutComponents/DoubleColumn';
import AssetTree from '../../../../components/AssetTree';
import Tabs from '../../../../../Template/layoutComponents/Tabs';
import Forms from '../../../../../Template/layoutComponents/Forms';

import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import useMaintenanceState from '../../../../atoms/variables';

import { concatChildren, findObjectById } from '../../../../../helpers/misc';

import newSystemIcon from '../../../../images/newSystem.svg';

import icons from '../../../../components/AssetTreeIcons';
import { CloseCircleOutlined } from '@ant-design/icons';

import { getExistingAssetTrees, newSystem, updateSystem } from '../../../../helpers/apiCalls';

import './style.css';
import { useMsal } from '@azure/msal-react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useTemplateState from '../../../../../Template/atoms/variables';
import InterconnectionsTab from './components/InterconnectionsTab';

const EditSystem = () => {
  const { plantId, systemId } = useParams();
  const navigate = useNavigate();
  const [notificationApi, contextHolder] = notification.useNotification();
  const { t } = useTranslation();

  const location = useLocation();

  const assetTypes = ['machine', 'assembly', 'equipment', 'component'];

  const { instance } = useMsal();
  const {
    chosenPlant,
    chosenSystem,
    setChosenSystem,
    departments,
    orgs,
    setSystems,
    setExistingAssetTrees,
    setChosenPlant,
    costcenters,
  } = useMaintenanceState();
  const { setPendingApiCalls } = useTemplateState();

  const [chosenLevel, setChosenLevel] = useState(null);
  const [openedLevels, setOpenedLevels] = useState([]);
  const [interconnections, setInterconnections] = useState([]);

  const [system, setSystem] = useState(chosenSystem);

  useEffect(() => {
    if (!chosenSystem) navigate(`/maintenance/plants/${plantId}/systems/${systemId}/view`);
  }, []);

  const disableSave = () => {
    return system?.properties?.some(
      (prop) =>
        prop.required === true &&
        (prop.value === '' || prop.value === null || prop.value == t('new_system')),
    );
    // || system?.children.length === 0
  };

  const updateSystemState = (current, inputIndex, event) => {
    let updateName = false;
    return {
      ...current,
      properties: [
        ...current.properties.map((item, index) => {
          if (index === inputIndex) {
            if (item.key === 'name') {
              updateName = event.target.value;
            }

            return {
              ...item,
              value: item.type.toLowerCase().includes('select')
                ? Array.isArray(event.target.value)
                  ? event.target.value.map((value) => ({
                      id: value,
                      label: '',
                    }))
                  : { id: event.target.value, label: '' }
                : item.type.toLowerCase().includes('bool')
                  ? event.target.value
                  : event.target.value,
            };
          }
          return item;
        }),
      ],
      name: updateName ? updateName : current.name,
    };
  };

  const addChildToSystem = (current, child) => {
    return {
      ...current,
      children: [...current.children, child.id],
    };
  };

  const removeChildToSystem = (current, child) => {
    return {
      ...current,
      children: current.children.filter((item) => item != child),
    };
  };

  const highlightAlreadyAdded = (childId) => {
    const element = document.getElementById(`systemComponentContainer-${childId}`);

    if (element) {
      const removeHighlightElements = document.getElementsByClassName('highlight');

      for (let element of removeHighlightElements) {
        element?.classList.remove('highlight');
      }

      element.scrollIntoView();
      element.classList.add('highlight');
    }
  };

  return (
    <div id='assets'>
      {contextHolder}
      <DoubleColumnTemplate
        customColumnGrow={[2, 2]}
        columnColors={['white', '#FBFBFB']}
        firstColumnContent={
          <Tabs
            chosenTabStyleOverride={{ height: 'fit-content', padding: 0 }}
            tabs={[
              {
                label: t('assets'),
                content: (
                  <div className='columnContainer' style={{ gap: '1rem', overflow: 'auto' }}>
                    <div className='columnHeader'>
                      <div className='columnTitle'>{t('asset_tree')}</div>
                    </div>
                    <div className='assetTreeContainer'>
                      {chosenPlant && (
                        <AssetTree
                          assetTree={chosenPlant}
                          onNodeClick={(item) => {
                            setOpenedLevels((current) => {
                              if (current.some((opened) => opened?.id === item?.id)) {
                                return [
                                  ...current.filter(
                                    (opened) =>
                                      opened?.id !== item?.id &&
                                      !concatChildren(item?.children)?.some(
                                        (child) => opened?.id === child?.id,
                                      ),
                                  ),
                                ];
                              } else {
                                return [...current, item];
                              }
                            });
                            setChosenLevel(item);
                          }}
                          isPlant={true}
                          systemPage={true}
                          isAsset={assetTypes.includes(chosenPlant?.type || chosenLevel?.type)}
                          handleAddClick={(item) => {
                            if (!system?.children.find((child) => child === item.id))
                              setSystem((current) => addChildToSystem(current, item));
                            else setSystem((current) => removeChildToSystem(current, item.id));
                          }}
                          children={system?.children}
                          chosenLevel={chosenLevel}
                          openedLevels={openedLevels}
                        />
                      )}
                    </div>
                  </div>
                ),
              },
              {
                label: t('interconnections'),
                content: (
                  <InterconnectionsTab
                    action={(item) => {
                      setInterconnections((current) => current.concat([item]));
                      if (!system?.children.find((child) => child === item.id))
                        setSystem((current) => addChildToSystem(current, item));
                      else setSystem((current) => removeChildToSystem(current, item.id));
                    }}
                    children={system?.children}
                  />
                ),
              },
            ]}
          />
        }
        secondColumnContent={
          <div className='columnContainer' style={{ gap: '1rem', overflow: 'auto' }}>
            <div className='columnHeader'>
              <div className='columnTitle'>{system?.name}</div>
              <Forms
                id={system?.id}
                inputs={system?.properties}
                onInputChange={(event, inputIndex) => {
                  setSystem((current) => updateSystemState(current, inputIndex, event));
                }}
                departments={departments}
                orgs={orgs}
                costcenters={costcenters}
              />
              <div className='columnTitle' style={{ paddingTop: '1rem' }}>
                {t('system_components')}
              </div>
            </div>

            <div className='systemComponentsContainer'>
              <div className='systemComponentsExtraPadding'>
                {system?.children
                  ?.map((item) => {
                    if (typeof item === 'string') {
                      let obj = findObjectById(chosenPlant ?? { children: [] }, item);

                      if (!obj)
                        obj = location.state?.items?.find(
                          (int) => int.id === item || int._id === item,
                        );

                      if (!obj)
                        obj = interconnections.find((int) => int.id === item || int._id === item);

                      return {
                        name: obj?.name,
                        id: obj?.id ?? obj?._id,
                        type: obj?.type?.toLowerCase() ?? 'interconnection',
                      };
                    } else return item;
                  })
                  .map((item) => {
                    return (
                      <div
                        id={`systemComponentContainer-${item.id}`}
                        key={`systemComponentContainer-${item.id}`}
                        className='systemComponentContainer'
                      >
                        <div className='systemComponentTitleContainer'>
                          <div className='systemComponentIcon'>
                            {icons.find((icon) => icon.type == item?.type)?.iconSystem}
                          </div>
                          <div className='systemComponentTitle'>{item.name}</div>
                        </div>
                        <div
                          className='systemComponentClose'
                          onClick={() => {
                            setSystem((current) => removeChildToSystem(current, item.id));
                          }}
                        >
                          <CloseCircleOutlined style={{ fontSize: ' 1.5rem', color: '#494949' }} />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className='manageSystemButtonsContainer'>
              <Button
                id='cancel'
                size='large'
                icon={<CloseCircleOutlined />}
                style={{
                  borderColor: 'black',
                  background: 'white',
                  color: 'black',
                }}
                onClick={() => {
                  navigate(`/maintenance/plants/${plantId}/systems/${systemId}/view`);
                }}
              >
                {t('cancel-btn')}
              </Button>
              <Button
                icon={<img src={newSystemIcon} />}
                size='large'
                disabled={disableSave()}
                style={{
                  borderColor: '#1777FF',
                  background: '#1777FF',
                  color: 'white',
                  opacity: disableSave() ? 0.5 : 1,
                }}
                onClick={async () => {
                  try {
                    setPendingApiCalls((current) => current.concat(['newSystem']));
                    if (system.id === 'newSystem') {
                      // SEND ITEM TO BFF (CREATE)
                      const mongoId = await newSystem(instance, {
                        ...system,
                        parent: plantId,
                      });
                      setChosenSystem({ ...system, id: mongoId, parent: plantId });

                      setSystems((current) =>
                        current.concat([
                          {
                            ...system,
                            id: mongoId,
                            label: system?.name,
                            parent: plantId,
                            icon: 'system',
                          },
                        ]),
                      );
                      setChosenSystem({
                        ...system,
                        id: mongoId,
                        label: system?.name,
                        parent: plantId,
                        icon: 'system',
                      });
                      navigate(`/maintenance/plants/${plantId}/systems/${mongoId}/view`);
                    } else {
                      // SEND ITEM TO BFF (UPDATE)
                      await updateSystem(instance, system);
                      setChosenSystem({ ...system, parent: plantId });
                      navigate(`/maintenance/plants/${plantId}/systems/${systemId}/view`);
                    }

                    const response = await getExistingAssetTrees(instance);
                    setExistingAssetTrees(response);
                    setChosenPlant(response.find((plant) => plant.id === plantId));
                  } catch (err) {
                    notificationApi.error({
                      message: t('database_communication_failure'),
                      description: err?.response?.data?.message
                        ? `${err?.response?.data?.message} (${err?.response?.status})`
                        : err?.message,
                      duration: 5,
                    });
                  } finally {
                    setPendingApiCalls((current) => current.filter((item) => item !== 'newSystem'));
                  }
                }}
              >
                {t('save_system')}
              </Button>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default EditSystem;
