import { acquireToken } from '../../auth/msal.js';
import { protectedResourcesScopes } from '../../auth/config';
import axios from 'axios';

const apiUrl = `${process.env.REACT_APP_BFF_API}`;
const scope = protectedResourcesScopes.core;

export const createConversation = async (instance, subjectId) => {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.post(
      `${apiUrl}/virtual-engineer/conversation`,
      {
        subject_id: subjectId,
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );
    return data;
  } catch (e) {
    console.log('erro ao criar conversa', e);
    throw e;
  }
};

export const sendMessage = async (instance, conversationId, subjectId, socketId, message) => {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.post(
      `${apiUrl}/virtual-engineer/conversation/${conversationId}/message`,
      {
        socketId,
        subjectId,
        message: { role: 'user', type: 'string', value: message },
      },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );
    return data;
  } catch (e) {
    throw e;
  }
};

export const getUserConversationHistory = async (instance, category) => {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.get(`${apiUrl}/virtual-engineer/conversation/user`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: category ? { 'subject.category': category.id } : null,
    });
    return data;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const getConversation = async (instance, threadId, userId) => {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.get(`${apiUrl}/virtual-engineer/conversation/${threadId}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
};
