import React, { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import useTemplateState from '../../atoms/variables';
import { useTranslation } from 'react-i18next';
import { useMsal } from '@azure/msal-react';
import useGlobalState from '../../atoms/variables';
import { getCompanyInfo, findOrCreateUser } from '../../helpers/apiCalls';
import { notification } from 'antd';
import ErrorPage from '../ErrorPage';

const ProtectedModule = () => {
  const { setPendingApiCalls, setCompanyInfo, setUser } = useGlobalState();
  const { companyInfo, user } = useTemplateState();
  const { instance } = useMsal();
  const { t } = useTranslation();
  const [notificationApi, contextHolder] = notification.useNotification();
  const [hasAccess, setHasAccess] = useState(false);
  const [loading, setLoading] = useState(true); // Indica se os dados ainda estão sendo carregados
  const { pathname } = useLocation();

  useEffect(() => {
    if (!user || !companyInfo) triggerApiCalls();
    else setLoading(false);
  }, []);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['getCompanyInfo', 'findOrCreateUser']));

      const responses = await Promise.all([getCompanyInfo(instance), findOrCreateUser(instance)]);
      setCompanyInfo(responses[0]);
      setUser(responses[1]);
    } catch (err) {
      console.log(err);
      // API fail notification
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
    } finally {
      setPendingApiCalls((current) =>
        current.filter((item) => item !== 'getCompanyInfo' && item !== 'findOrCreateUser'),
      );
      setLoading(false);
    }
  };

  const checkModuleAccess = (access, company, module) => {
    const modulesAccess = {
      '/maintenance': company?.defaultAccess?.includes('/maintenance') ? 'default' : 'admin',
      '/settings': company?.defaultAccess?.includes('/settings') ? 'default' : 'admin',
      '/virtual-engineer': company?.defaultAccess?.includes('/virtual-engineer')
        ? 'default'
        : 'admin',
      '/safety-copilot': company?.defaultAccess?.includes('/safety-copilot') ? 'default' : 'admin',
    };

    if (modulesAccess[module] === 'default') {
      return true;
    } else return access.includes(modulesAccess[module]);
  };

  useEffect(() => {
    let module = '/' + pathname.slice(1).split('/')[0];
    setHasAccess(
      companyInfo?.modules.includes(module) && checkModuleAccess(user.access, companyInfo, module),
    );
  }, [pathname, companyInfo, user]);

  if (loading) return;

  if (!hasAccess) {
    return <ErrorPage customMessage={`Oops...`} />;
  }
  return (
    <>
      {contextHolder}
      <Outlet />
    </>
  );
};

export default ProtectedModule;
