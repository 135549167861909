import React from 'react';
import moment from 'moment';
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    Image,
    Font
} from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

// Importe as fontes localmente
import RobotoRegular from './fonts/Roboto-Regular.ttf';
import RobotoMedium from './fonts/Roboto-Medium.ttf';
import RobotoBold from './fonts/Roboto-Bold.ttf';
import DrSugiyama from './fonts/DrSugiyama-Regular.ttf';

// Importar fonte para símbolos
import NotoSansSymbols from './fonts/NotoSansSymbols-Regular.ttf';


// Registrar fontes
Font.register({
    family: 'Roboto',
    fonts: [
        { src: RobotoRegular, fontWeight: 400 },
        { src: RobotoMedium, fontWeight: 500 },
        { src: RobotoBold, fontWeight: 700 }
    ]
});

Font.register({
    family: 'DrSugiyama',  // Mudou de 'Dr Sugiyama' para 'DrSugiyama' (sem espaço)
    src: DrSugiyama,
    fontWeight: 'normal',
    fontStyle: 'normal'
});

Font.register({
    family: 'NotoSansSymbols',
    src: NotoSansSymbols
});

// Estilos
const signatureTextStyle = {
    fontFamily: 'DrSugiyama',
    fontSize: 24,
    height: 32,
    marginVertical: 4,
    textAlign: 'center',
    color: '#225968'
};

const styles = StyleSheet.create({
    page: {
        padding: 10,
        fontFamily: 'Roboto',
        fontSize: 10
    },
    reportContainer: {
        maxWidth: '100%',
        margin: '0 auto'
    },
    reportHeader: {
        backgroundColor: '#567a8d',
        borderBottomLeftRadius: 3,
        borderBottomRightRadius: 3,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 8,
        minHeight: 50,
        marginBottom: 2
    },
    logoOverview: {
        maxWidth: 120,
        maxHeight: 40
    },
    title: {
        color: 'white',
        fontSize: 10,
        alignSelf: 'flex-end'
    },
    reportContent: {
        flexDirection: 'column',
        gap: 4
    },
    reportTable: {
        marginBottom: 0,
        width: '100%',
        backgroundColor: 'white'
    },
    reportTableTitle: {
        backgroundColor: '#567a8d',
        padding: 4,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
    },
    reportTableTitleText: {
        color: 'white',
        fontSize: 8,
        fontWeight: 500
    },
    tableCell: {
        padding: 4,
        fontSize: 8,
        borderWidth: 1,
        borderColor: '#ddd'
    },
    tableLabelCell: {
        width: '30%',
        color: '#225968'
    },
    tableValueCell: {
        width: '20%'
    },
    evidencesGrid: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        padding: 4,
        gap: 4
    },
    evidenceItem: {
        width: '16%'
    },
    evidenceImage: {
        width: '100%',
        height: 80,
        objectFit: 'cover',
        borderRadius: 5
    },
    evidenceTitle: {
        fontSize: 6,
        marginTop: 2,
        color: '#225968',
        textAlign: 'center',
        color: 'black',
    },
    signatureSection: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        padding: 4,
        gap: 4,
        alignContent: 'center',
    },
    signatureBlock: {
        width: '23%',
        padding: 4
    },
    signatureTitle: {
        color: '#225968',
        fontSize: 10,
        fontWeight: 'bold',
        marginBottom: 8,
        textAlign: 'center'
    },
    signature: {
        ...signatureTextStyle
    },
    signatureLine: {
        borderTopWidth: 2,
        borderColor: '#225968',
        width: '90%',
        marginVertical: 8,
        alignSelf: 'center'
    },
    signatureName: {
        color: '#225968',
        fontSize: 8,
        fontWeight: 'bold',
        textAlign: 'center'
    },
    signatureEmail: {
        color: '#225968',
        fontSize: 6,
        textAlign: 'center'
    },
    signatureCompany: {
        color: '#225968',
        fontSize: 6,
        textAlign: 'center'
    }
});

const ReportTemplate = ({ report, logoUrl }) => {
    const { t, i18n } = useTranslation();

    // Componentes auxiliares
    const ValueText = ({ value }) => {
        const parts = String(value).split(/(Ω)/);
        return (
            <Text>
                {parts.map((part, i) =>
                    part === 'Ω' ? (
                        <Text key={i} style={{ fontFamily: 'NotoSansSymbols' }}>Ω</Text>
                    ) : (
                        <Text key={i}>{part}</Text>
                    )
                )}
            </Text>
        );
    };

    const TableRow = ({ content }) => (
        <View style={{ flexDirection: 'row' }}>
            {content.map((info, index) => (
                <React.Fragment key={index}>
                    <View style={[styles.tableCell, styles.tableLabelCell]}>
                        <Text>{info.title}</Text>
                    </View>
                    <View style={[styles.tableCell, styles.tableValueCell]}>
                        <Text style={{ color: info.valid ? 'black' : 'red' }}>
                            {typeof info.value === 'object'
                                ? <ValueText value={Object.values(info.value)
                                    .map((value) => value.value)
                                    .join(' ')} />
                                : <ValueText value={info.value} />
                            }
                        </Text>
                        <Text style={{ color: info.valid ? 'black' : 'red', fontFamily: 'NotoSansSymbols' }}>{!info.valid ? ' ⚠' : ''}</Text>
                    </View>
                </React.Fragment>
            ))}
        </View>
    );

    const ReportTable = ({ title, content }) => (
        <View style={styles.reportTable}>
            <View style={styles.reportTableTitle}>
                <Text style={styles.reportTableTitleText}>{title}</Text>
            </View>
            <View>
                {Array.from({ length: Math.ceil(content.length / 2) }).map((_, rowIndex) => (
                    <TableRow
                        key={rowIndex}
                        content={content.slice(rowIndex * 2, rowIndex * 2 + 2)}
                    />
                ))}
            </View>
        </View>
    );

    const Evidences = ({ images }) => {
        return (
            <View style={styles.reportTable}>
                <View style={styles.reportTableTitle}>
                    <Text style={styles.reportTableTitleText}>{t('evidences')}</Text>
                </View>
                <View style={styles.evidencesGrid}>
                    {images.map((image, index) => (
                        <View key={index} style={styles.evidenceItem}>
                            <Image
                                src={image.url}
                                style={styles.evidenceImage}
                            />
                            <Text style={styles.evidenceTitle}>
                                {image.title?.toUpperCase()}
                            </Text>
                        </View>
                    ))}
                </View>
            </View>
        );
    };

    // Funções auxiliares

    const formatName = (name) => {
        if (!name) return null;
        if (name.length <= 14) return name;
        const split_name = name.split(' ');
        return split_name.map((part, index) => (index === 0 ? part : `${part.charAt(0)}.`)).join(' ');
    };

    const generateGeneralInfo = (report) => {
        const content = [
            { title: t('contractor') + ': ', value: report.employee.properties.find((p) => p.key === 'company').value, valid: true },
            {
                title: t('date') + ':',
                value: report.updatedAt ? moment(report.updatedAt).format('DD/MM/YYYY') : 'N/A',
                valid: true,
            },
            { title: t('location') + ':', value: report.subject?.location?.name, valid: true },
            { title: t('name') + ':', value: report.subject?.name, valid: true },
            { title: t('token') + ':', value: report.token, valid: true },
            { title: t('attempt') + ':', value: report.attempt, valid: true },
            { title: t('origin') + ':', value: report.subject.properties.find(p => p.key === 'origin')?.value, valid: true },
            { title: t('destiny') + ':', value: report.subject.properties.find(p => p.key === 'destiny')?.value, valid: true },
        ];

        return content;
    };

    const generateContentPhases = (report) => {
        return report.phase
            .map((phase) => {
                if (phase.type === 'form') return renderFormPhase(phase);
                if (phase.type === 'camera') return renderCameraPhase(phase);
                return null;
            })
            .filter(Boolean);
    };

    const renderFormPhase = (phase) => {
        const content = phase.fields.map((field) => ({
            title: field.label,
            value: field.userGivenValue ? field.userGivenValue[0] : '-',
            valid: field.isGoodToGo,
        }));
        return { title: phase.title, content };
    };

    const mapMeggerContent = (step, stepIndex) => {
        return {
            title: step.title,
            value: {
                reading: { value: step.imageAnalysis?.reading },
                reading_unit: {
                    value: step.imageAnalysis?.reading_unit,
                    type: 'select',
                },
            },
            valid: step.valid,
            index: stepIndex,
        };
    };

    const mapHipotContent = (step, stepIndex) => {
        return Object.entries(step.imageAnalysis)
            .map(([key, value]) => {
                if (key !== 'valid' && key !== 'operator') {
                    return {
                        title: t(key) ?? key,
                        value: value,
                        valid: key === 'time_count' ? step.valid : true,
                        index: stepIndex,
                        key,
                    };
                }
                return null;
            })
            .filter(Boolean);
    };

    const renderCameraPhase = (phase) => {
        const content = phase.steps
            .map((step, stepIndex) => {
                if (step.imageAnalysis) {
                    if (step.imageAnalysis?.reading && step.imageAnalysis?.reading_unit) {
                        return mapMeggerContent(step, stepIndex);
                    }
                    return mapHipotContent(step, stepIndex);
                }
                return null;
            })
            .filter(Boolean)
            .flat();

        return content.length > 0 ? { title: phase.title, content } : null;
    };

    // SignatureBlock agora tem acesso direto ao t do useTranslation
    const SignatureBlock = ({ title, data }) => (
        <View style={styles.signatureBlock}>
            <Text style={styles.signatureTitle}>{title}</Text>
            <Text style={{ fontFamily: 'DrSugiyama', fontSize: 20, height: 32, marginVertical: 0, textAlign: 'center', color: 'black' }}>
                {data?.sign_name || ''}
            </Text>
            <View style={styles.signatureLine} />
            <Text style={styles.signatureName}>
                {data ? `${data.reproved ? t('reproved_by') : ''} ${data.name?.toUpperCase() || ' '}` : ' '}
            </Text>
            <Text style={styles.signatureEmail}>
                {data?.email?.toUpperCase() || ' '}
            </Text>
            <Text style={styles.signatureCompany}>
                {data?.company?.toUpperCase() || ' '}
            </Text>
        </View>
    );

    // Componente principal retornando o PDF
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.reportContainer}>
                    <View style={styles.reportHeader}>
                        {logoUrl && <Image src={logoUrl} style={styles.logoOverview} />}
                        <View style={styles.title}>
                            <Text>
                                {report.test[i18n.language].reportName}
                            </Text>
                        </View>
                        <View style={styles.title}>
                            <Text>
                                {`${report.code}-${report.subject?.location?.name ?? 'N/A'}-${report.sequential.toString().padStart(4, "0")}`}
                            </Text>
                        </View>
                    </View>

                    <View style={styles.reportContent}>
                        <ReportTable
                            title={t('general_tab')}
                            content={generateGeneralInfo(report)}
                        />

                        {generateContentPhases(report).map((phase, index) => (
                            <ReportTable
                                key={index}
                                title={phase.title}
                                content={phase.content}
                            />
                        ))}

                        {report.phase
                            .filter((phase) => phase.type === 'camera' || phase.type === 'evidency')
                            .map((phase) => phase.steps)
                            .flat()
                            .map((step) => step.images)
                            .flat()
                            .length > 0 && (
                                <Evidences
                                    images={report.phase
                                        .filter((phase) => phase.type === 'camera' || phase.type === 'evidency')
                                        .map((phase) => phase.steps)
                                        .flat()
                                        .map((step) =>
                                            step.images.map((image) => ({
                                                url: image.url,
                                                title: step.title,
                                            }))
                                        )
                                        .flat()}
                                />
                            )}

                        <View style={styles.reportTable}>
                            <View style={styles.reportTableTitle}>
                                <Text style={styles.reportTableTitleText}>{t('signatures')}</Text>
                            </View>
                            <View style={styles.signatureSection}>
                                <SignatureBlock
                                    title={t('executor')}
                                    data={{
                                        name: report?.employee?.properties.find((prop) => prop.key === 'name')?.value,
                                        sign_name: formatName(
                                            report?.employee?.properties.find((prop) => prop.key === 'name')?.value
                                        ),
                                        email: report?.employee?.email,
                                        company: report?.employee?.properties.find((prop) => prop.key === 'company')?.value,
                                    }}
                                />
                                {report.report.signatures?.map((signature, index) => (
                                    <SignatureBlock
                                        key={index}
                                        title={t(`report_${signature.anchor}`)}
                                        data={
                                            signature.role
                                                ? {
                                                    name: signature?.role?.name,
                                                    sign_name: formatName(signature?.role?.name),
                                                    email: signature?.role?.email,
                                                    company: signature?.role?.company,
                                                    reproved: !signature?.approve,
                                                }
                                                : null
                                        }
                                    />
                                ))}
                            </View>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export default ReportTemplate;