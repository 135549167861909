import { useEffect, useState } from 'react';
import Table from '../../../../Template/layoutComponents/Table';
import { getInterconnectionAndUniques } from '../../../helpers/apiCalls';
import useTemplateState from '../../../../Template/atoms/variables';
import useMaintenanceState from '../../../atoms/variables';
import { notification } from 'antd';
import { getInterconnectionCategories } from '../../../../Settings/helpers/apiCalls';
import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import TableTip from '../../../../Template/layoutComponents/Table/components/tip';

const InterconnectionTable = ({ selectedObjects, setSelectedObjects }) => {
  const { instance } = useMsal();
  const { t } = useTranslation();

  const { plantId } = useParams();

  const [notificationApi, contextHolder] = notification.useNotification();

  const { setPendingApiCalls } = useTemplateState();
  const { interconnections, setInterconnections, orgs, setViewDetailsItem } = useMaintenanceState();

  const [allCategories, setAllCategories] = useState([]);

  useEffect(() => {
    triggerApiCalls();
  }, []);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) =>
        current.concat(['getInterconnectionAndUniques', 'getInterconnectionCategories']),
      );

      const responses = await Promise.all([
        getInterconnectionAndUniques(instance, { location: plantId, status: 'Pending', limit: 10 }),
        getInterconnectionCategories(instance),
      ]);
      const interconnectionsResponse = responses[0];
      interconnectionsResponse.items.map((item) => {
        item.from = item.properties.find((p) => p.key === 'origin').value;
        item.to = item.properties.find((p) => p.key === 'destiny').value;
      });
      setInterconnections(interconnectionsResponse);
      setAllCategories(responses[1]);
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
      console.log(err);
    } finally {
      setPendingApiCalls((current) =>
        current.filter(
          (item) =>
            item !== 'getInterconnectionAndUniques' && item !== 'getInterconnectionCategories',
        ),
      );
    }
  };

  return (
    <>
      {contextHolder}
      <Table
        handleSelect={(selected) => {
          if (selectedObjects.filter((obj) => !obj.committed).length > selected.length) {
            setSelectedObjects((current) =>
              current.filter(
                (obj) => obj.committed || selected.some((newObj) => newObj._id === obj._id),
              ),
            );
          } else
            setSelectedObjects((current) => {
              return current.concat(
                selected
                  .filter((newObj) => !current.some((obj) => obj._id === newObj._id))
                  .map((newObj) => ({ ...newObj, kind: 'Interconnection' })),
              );
            });
        }}
        handleRowClick={null}
        apiCall={async (instance, queryParams) => {
          const response = await getInterconnectionAndUniques(instance, {
            location: plantId,
            ...queryParams,
            status: 'Pending',
          });

          response.items.map((item) => {
            item.from = item.properties.find((p) => p.key === 'origin').value;
            item.to = item.properties.find((p) => p.key === 'destiny').value;
          });
          return response;
        }}
        items={interconnections?.items}
        tableSettings={{
          totalItems: interconnections.totalItems ?? 0,
          columnsConfig: {
            category: {
              label: t('category'),
              replaceValue: (value) =>
                allCategories.find((cat) => cat._id === value || cat.id === value),
            },
            name: {
              label: t('name'),
            },

            status: {
              label: t('status'),
              customElement: (item) => <TableTip children={item.status ?? item} />,
            },
          },
          sortable: ['category', 'name'],
          listFilter: ['category'],
          searchFilter: ['name'],
          uniqueValues: interconnections.uniqueValues,
        }}
      />
    </>
  );
};
export default InterconnectionTable;
