export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function concatChildren(arr) {
  return arr?.reduce((acc, obj) => {
    acc.push(obj);
    if (obj.children) {
      acc = acc.concat(concatChildren(obj.children));
    }
    return acc;
  }, []);
}

export function findObjPath(obj, search, searchedKey, path = []) {
  if (obj[searchedKey] === search) {
    return [...path, 'children'];
  }
  if (obj.children) {
    for (let i = 0; i < obj.children.length; i++) {
      const child = obj.children[i];
      const result = findObjPath(child, search, searchedKey, [...path, 'children', i]);
      if (result) return result;
    }
  }
  return null;
}

export function addItemToTree(obj, pathArray, newValue) {
  if (pathArray.length === 0) return obj; // Verifica se o caminho está vazio

  // Cria uma cópia do objeto raiz para preservar a imutabilidade
  const newObj = { ...obj };
  let targetObj = newObj; // Referência para navegar pela cópia

  // Percorre o caminho, exceto o último item, fazendo cópias dos objetos no caminho
  for (let i = 0; i < pathArray.length - 1; i++) {
    const key = pathArray[i];

    // Faz uma cópia do objeto/array para manter a imutabilidade
    targetObj[key] = Array.isArray(targetObj[key]) ? [...targetObj[key]] : { ...targetObj[key] };
    targetObj = targetObj[key];
  }

  // O último item da pathArray é o array onde queremos adicionar o novo item
  const lastKey = pathArray[pathArray.length - 1];

  // Faz o push do newValue no array de children correto
  targetObj[lastKey] = [
    {
      ...newValue,
      properties: newValue.properties.map((prop) => {
        if (prop.main && !prop.allowEdit) prop.disabled = true;
        return prop;
      }),
    },
    ...targetObj[lastKey],
  ];

  return newObj; // Retorna a árvore modificada
}

export function updateItemInTree(obj, id, newValue) {
  // Se o objeto atual tem o ID correspondente, retorna uma cópia com o newValue
  if (obj.id === id) {
    return { ...obj, ...newValue };
  }

  // Faz uma cópia do objeto para garantir imutabilidade
  const newObj = { ...obj };

  // Verifica se o objeto tem filhos (children) e itera sobre eles recursivamente
  if (newObj.children && Array.isArray(newObj.children)) {
    newObj.children = newObj.children.map((child) => updateItemInTree(child, id, newValue));
  }

  return newObj; // Retorna a árvore modificada ou a original se o ID não for encontrado
}

export function findObjectById(obj, targetId) {
  if (obj.id === targetId) {
    return obj;
  }

  for (const child of obj.children) {
    const result = findObjectById(child, targetId);
    if (result) {
      return result;
    }
  }

  return null;
};

export function findParentIdsById(tree, targetId) {
  function traverse(node, path = []) {
    // Se o ID atual for o que estamos procurando e estamos na raiz, retorna array vazio
    if (node.id === targetId && path.length === 0) {
      return [];
    }

    // Se o ID atual for o que estamos procurando, retorna o caminho até ele
    if (node.id === targetId) {
      return path;
    }

    // Se houver children, percorre cada child
    if (node.children && node.children.length > 0) {
      for (let child of node.children) {
        const result = traverse(child, [...path, node.id]);
        if (result) {
          return result; // Retorna o caminho assim que encontrar o ID
        }
      }
    }

    // Retorna null se não encontrar o ID
    return null;
  }

  return traverse(tree) || [];
}

export function parseIsoDateString(dateString) {
  return dateString.split('T')[0].split('-').reverse().join('/');
}

export function parseIsoDateStringWithTime(dateString) {
  return `${dateString.split('T')[0].split('-').reverse().join('/')} ${dateString.split('T')[1].split('.')[0]}`;
}

export function parseIsoTimeString(dateString) {
  return dateString.split('T')[1].split('.')[0];
}

export function isDivOutOfViewport(div, gap = 200) {
  const rect = div?.getBoundingClientRect();
  const isOutOfViewport =
    rect?.top < 0 ||
    rect?.left < 0 ||
    rect?.bottom + gap > window.innerHeight ||
    rect?.right + gap > window.innerWidth;


  return isOutOfViewport;
}

export function findById(tree, targetId) {
  if (tree.id === targetId) {
    return tree;
  }

  for (let child of tree.children) {
    const result = findById(child, targetId);
    if (result) {
      return result;
    }
  }

  return null;
}

