import { useCallback } from 'react';
import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';
import { updateTask } from '../../../../../../../../../../helpers/apiCalls';
import useGlobalState from '../../../../../../../../../../../Template/atoms/variables';
/**
 * Hook para gerenciar o controle de versões de um relatório
 * @typedef {Object} Version
 * @property {number} version - Número da versão
 * @property {string} date - Data de criação da versão
 * @property {Object} author - Dados do autor da versão
 * @property {Array} content - Conteúdo da versão
 * @property {Array} changes - Alterações em relação à versão anterior
 * 
 * @param {Object} params - Parâmetros do hook
 * @param {Object} params.report - Relatório atual
 * @param {Function} params.setReport - Função para atualizar o relatório
 * @param {Object} params.originalReport - Versão original do relatório
 * @param {string|number} params.selectedVersion - Versão selecionada
 * @param {Function} params.setSelectedVersion - Função para atualizar a versão selecionada
 * @returns {Object} Funções para manipulação de versões
 */
export const useVersionControl = ({ report, setReport, originalReport, selectedVersion, setSelectedVersion, notificationApi, currentUser, setTasks }) => {

    const { instance } = useMsal();

    const { setPendingApiCalls } = useGlobalState();

    const { t } = useTranslation();

    const handleVersionChange = useCallback((version) => {
        setSelectedVersion(version);
    }, []);

    const handlePhaseValueChange = useCallback((phaseIndex, fieldIndex, newValue, field = null) => {
        const updatedReport = { ...report };
        let editedPhase = updatedReport.phase[phaseIndex];
        if (editedPhase.type === 'form') {
            editedPhase.fields[fieldIndex].userGivenValue = [newValue];
        } else if (editedPhase.type === 'camera') {
            editedPhase.steps[fieldIndex].imageAnalysis[field] = newValue;
        }
        setReport(updatedReport);
    }, [report, setReport]);

    const handleCancel = useCallback(() => {
        if (originalReport) {
            setReport(originalReport);
        }
    }, [originalReport, setReport]);

    const handleSave = useCallback(async () => {
        try {
            setPendingApiCalls((current) => current.concat(['updateTask']));
            const currentContent = JSON.parse(JSON.stringify(report.phase));

            const newVersion = {
                version: (report.report.versions?.length || 0) + 1,
                date: new Date(),
                author: currentUser,
                content: currentContent,
            };
            let updatedReport = {
                ...report,
                phase: currentContent,
                report: {
                    ...report.report,
                    latestVersion: newVersion.version,
                    versions: [...(report.report.versions || []), newVersion],
                },
            };
            updatedReport = await updateTask(instance, updatedReport._id, updatedReport);
            setReport(updatedReport);
            setTasks((current) => ({ ...current, items: current.items.map((item) => item._id === updatedReport._id ? updatedReport : item) }));
        } catch (err) {
            notificationApi.error({
                message: t('database_communication_failure'),
                description: err?.response?.data?.message
                    ? `${err?.response?.data?.message} (${err?.response?.status})`
                    : err?.message,
                duration: 5,
            });
            setReport(originalReport);
            console.log(err);
        } finally {
            setPendingApiCalls((current) => current?.filter((item) => item !== 'updateTask'));
        }

    }, [report, setReport, currentUser]);

    const getVersionContent = useCallback(() => {
        const version = report.versions?.find(v => v.version === selectedVersion);
        return version ? version.content : report.phase;
    }, [report, selectedVersion]);

    return {
        handleVersionChange,
        handlePhaseValueChange,
        handleSave,
        handleCancel,
        getVersionContent
    };
};
