import { useEffect, useState } from 'react';
import ArrowSteps from '../../../../../components/CommissioningProgress';
import DocumentUpload from './CriteriaTypes/DocumentUpload';
import { useParams } from 'react-router-dom';
import {
  getAssetById,
  updateAsset,
  getAssetCategoryById,
  getInterconnectionById,
  getInterconnectionCategoryById,
  updateInterconnection,
} from '../../../../../helpers/apiCalls';
import { useMsal } from '@azure/msal-react';
import useMaintenanceState from '../../../../../atoms/variables';
import { findById } from '../../../../../../helpers/misc';
import useTemplateState from '../../../../../../Template/atoms/variables';
import { Button, notification } from 'antd';
import { useTranslation } from 'react-i18next';

import './style.css';
import SystemDependency from './CriteriaTypes/SystemDependency';
import TaskReports from './CriteriaTypes/TaskReports';

const assetTypes = ['machine', 'assembly', 'equipment', 'component'];

const CommissioningTab = ({
  steps = [
    { label: 'L1' },
    { label: 'L2' },
    { label: 'L3' },
    { label: 'L4' },
    { label: 'L5' },
    { label: 'Operating' },
  ],
}) => {
  const { plantId, assetId, interconnectionId } = useParams();

  const { t } = useTranslation();
  const { instance } = useMsal();
  const { existingAssetTrees, viewDetailsItem } = useMaintenanceState();
  const [notificationApi, contextHolder] = notification.useNotification();

  const { setPendingApiCalls } = useTemplateState();

  const [currentStep, setCurrentStep] = useState(0);

  const [asset, setAsset] = useState(null);
  const [category, setCategory] = useState(null);

  const [stepProgress, setStepProgress] = useState({
    L1: 0,
    L2: 0,
    L3: 0,
    L4: 0,
    L5: 0,
  });
  const [mandatoryStepsCompletion, setMandatoryCompletion] = useState({
    L1: false,
    L2: false,
    L3: false,
    L4: false,
    L5: false,
  });
  const [completedSteps, setCompletedSteps] = useState({
    L1: false,
    L2: false,
    L3: false,
    L4: false,
    L5: false,
  });

  const [allTasks, setAllTasks] = useState([]);

  useEffect(() => {
    if (viewDetailsItem) triggerApiCalls();
  }, [viewDetailsItem]);

  useEffect(() => {
    if (asset?.completedSteps) {
      let curr = Object.values(asset.completedSteps).indexOf(false);
      if (curr === -1) curr = steps.length - 1;
      setCurrentStep(curr);
    }
  }, [asset]);

  useEffect(() => {
    const phaseTitle = category?.commissioning?.phases[currentStep]?.name;

    const taskIds = category?.commissioning?.phases[currentStep]?.stages?.map((stage) => stage._id);
    const approvedTasks = allTasks?.filter(
      (task) => task?.report?.status?.toLowerCase() === 'approved',
    );

    const completedTaskIds = taskIds?.filter((taskId) =>
      approvedTasks.some((task) => task?.test?._id === taskId),
    );

    setMandatoryCompletion((prev) => {
      return {
        ...prev,
        [phaseTitle]: completedTaskIds?.length === taskIds?.length,
      };
    });

    setStepProgress((prev) => {
      return {
        ...prev,
        [phaseTitle]: Math.round((completedTaskIds?.length / taskIds?.length) * 100),
      };
    });
  }, [allTasks]);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['getAssetById']));

      const responses = await Promise.all([
        assetId
          ? getAssetById(instance, assetId)
          : getInterconnectionById(instance, interconnectionId),
        assetId
          ? getAssetCategoryById(
              instance,
              viewDetailsItem?.category ??
                viewDetailsItem?.properties.find((p) => p.key === 'category')?.value?.id,
            )
          : getInterconnectionCategoryById(
              instance,
              viewDetailsItem?.category ??
                viewDetailsItem?.properties.find((p) => p.key === 'category')?.value?.id,
            ),
      ]);

      const asset = responses[0];
      setAsset(asset);
      setCompletedSteps(asset.completedSteps);
      setCategory(responses[1]);
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
      console.log(err);
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'getAssetById'));
    }
  };

  const handleNextStep = async () => {
    const currentLabel = steps[currentStep].label;
    if (mandatoryStepsCompletion[currentLabel]) {
      const updatedCompletedSteps = {
        ...completedSteps,
        [currentLabel]: true,
      };
      setCompletedSteps(updatedCompletedSteps);
      try {
        await saveCompletedSteps(updatedCompletedSteps);
      } catch (err) {
        console.error('Failed to save locked steps:', err);
      }
      setCurrentStep((prev) => Math.min(prev + 1, 5));
    } else {
      console.warn(`Cannot proceed. Step ${currentLabel} is not completed.`);
    }
  };

  const saveCompletedSteps = async (updatedCompleted) => {
    setPendingApiCalls((current) => current.concat(['updateAsset']));

    try {
      const payload = {
        completedSteps: updatedCompleted,
      };
      if (assetId) updateAsset(instance, assetId, payload);
      else updateInterconnection(instance, interconnectionId, payload);
    } catch (err) {
      console.error('Failed to update locked steps:', err);
    }
    setPendingApiCalls((current) => current.filter((item) => item !== 'updateAsset'));
  };

  const findItem = (id) => {
    if (existingAssetTrees) {
      let tree = existingAssetTrees.find((tree) => tree?.id === plantId);
      if (tree) {
        return findById(tree, id);
      }
    }
  };

  const handlePhaseStages = (stage, stageIndex, phaseTitle) => {
    if (stage?.['en']?.platform === 'mobile') {
      return (
        <TaskReports
          stage={stage}
          currentStepLabel={steps[currentStep]?.label ?? ''}
          setAllTasks={setAllTasks}
        />
      );
    } else {
      switch (stage?.['en']?.key) {
        case 'file-upload':
          return (
            <DocumentUpload
              asset={asset}
              phaseTitle={phaseTitle}
              stage={stage}
              stageIndex={stageIndex}
              type={
                interconnectionId
                  ? 'interconnection'
                  : assetTypes.includes(findItem(assetId)?.type)
                    ? 'asset'
                    : 'position'
              }
              setStepProgress={setStepProgress}
              isLocked={completedSteps[phaseTitle]}
              setMandatoryCompletion={setMandatoryCompletion}
            />
          );
        case 'system-dependency':
          return (
            <SystemDependency
              asset={asset}
              phaseTitle={phaseTitle}
              setMandatoryCompletion={setMandatoryCompletion}
              setStepProgress={setStepProgress}
            />
          );
        default:
          return <div>Criteria Component not fond</div>;
      }
    }
  };

  return (
    <div className='arrowStepsContainer'>
      {contextHolder}
      <ArrowSteps
        currentStep={currentStep}
        mandatoryStepsCompletion={mandatoryStepsCompletion}
        stepProgress={stepProgress}
        components={category?.commissioning?.phases?.map((phase) => {
          return (
            <div className='criteriaContainer'>
              {phase.stages.map((stage, index) => handlePhaseStages(stage, index, phase?.name))}
              <div style={{ textAlign: 'right' }}>
                <Button
                  type='primary'
                  disabled={!mandatoryStepsCompletion[phase?.name]}
                  onClick={handleNextStep}
                  size='large'
                  style={{
                    borderColor: '#1777FF',
                    background: '#1777FF',
                    color: 'white',
                    opacity: mandatoryStepsCompletion[phase?.name] ? 1 : 0.5,
                  }}
                >
                  {t('next')}
                </Button>
              </div>
            </div>
          );
        })}
        steps={steps}
        onStepChange={(step) => setCurrentStep(step)}
      />
    </div>
  );
};

export default CommissioningTab;
