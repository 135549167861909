import { acquireToken } from '../../auth/msal.js';
import { protectedResourcesScopes } from '../../auth/config.js';
import axios from 'axios';

const apiUrl = `${process.env.REACT_APP_BFF_API}`;
const scope = protectedResourcesScopes.core;

export const getEquipmentCategories = async (instance) => {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.get(`${apiUrl}/maintenance/assetCategory?limit=100`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data.items;
  } catch (e) {
    throw e;
  }
};
