class ReportTranslationService {
    static getTestTranslation(report, language) {
        if (!report) {
            console.error('Report object is null or undefined');
            return null;
        }

        if (!report.test) {
            console.error('Report test data is missing');
            return null;
        }
        return report.test[language] || report.test.en || report.test.pt;
    }

    static translatePhase(phase, testPhase) {
        if (!phase || !testPhase) {
            console.log('phase or testPhase is null or undefined');
            return phase
        };

        const translatedPhase = { ...phase };
        translatedPhase.title = testPhase.title;

        if (phase.type === 'form' && phase.fields) {
            translatedPhase.fields = phase.fields.map((field, index) => {
                const testField = testPhase.fields[index];
                let userGivenValue = field.userGivenValue;
                if (field.type === 'select') {
                    if (userGivenValue) {
                        const valueIndex = field.choices.indexOf(field.userGivenValue[0]);
                        const translatedValue = testField?.options[valueIndex] ?? field.userGivenValue[0];
                        userGivenValue = [translatedValue];
                    }
                }
                return {
                    ...field,
                    label: testField?.label || field.label,
                    choices: field.choices,
                    userGivenValue
                };
            });
        }

        if (phase.type === 'camera' && phase.steps) {
            translatedPhase.steps = phase.steps.map((step, index) => {
                const testStep = testPhase.steps[index];
                return {
                    ...step,
                    title: testStep?.title || step.title
                };
            });
        }

        return translatedPhase;
    }

    static translateReport(report, language) {
        const test = this.getTestTranslation(report, language);
        if (!test) return report;
        const translatedPhases = report.phase.map((phase, index) => {
            const testPhase = test.plan.steps[index];
            return this.translatePhase(phase, testPhase, language);
        });
        return {
            ...report,
            phase: translatedPhases
        };
    }
}

export default ReportTranslationService;
