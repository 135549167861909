import axios from 'axios';

import { protectedResourcesScopes } from '../../auth/config';
import { acquireToken } from '../../auth/msal.js';

const apiUrl = `${process.env.REACT_APP_BFF_API}`;
const scope = protectedResourcesScopes.core;

export async function getExistingAssetTrees(instance) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.get(`${apiUrl}/maintenance/tree`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getPlants(instance) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.get(`${apiUrl}/maintenance/position`, {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: { parent: 'null' },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function newAssetTreeItem(instance, item) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.post(`${apiUrl}/maintenance/tree`, item, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function updateAssetTreeItem(instance, item) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.put(`${apiUrl}/maintenance/tree/${item.id}`, item, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getAssets(instance, queryParams = {}) {
  const accessToken = await acquireToken(instance, scope);
  try {
    const { data } = await axios.get(`${apiUrl}/maintenance/asset`, {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: { ...queryParams, uniqueValues: true },
    });

    return data;
  } catch (e) {
    throw e;
  }
}

export async function getStageTasks(instance, queryParams = {}) {
  const accessToken = await acquireToken(instance, scope);
  try {
    const { data } = await axios.get(`${apiUrl}/commissioning/task`, {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: { ...queryParams },
    });

    return data;
  } catch (e) {
    throw e;
  }
}
export async function updateTask(instance, taskId, updatedTask) {
  const accessToken = await acquireToken(instance, scope);
  try {
    const { data } = await axios.put(`${apiUrl}/commissioning/task/${taskId}`, updatedTask, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return data;
  } catch (e) {
    throw e;
  }
}
export async function signTasks(instance, ids, action = 'approve') {
  const accessToken = await acquireToken(instance, scope);
  try {
    const { data } = await axios.post(`${apiUrl}/commissioning/task/signature/${action}`, { ids }, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return data;
  } catch (e) {
    throw e;
  }
}

export async function downloadTasksExport(instance, locationId) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const response = await axios.get(`${apiUrl}/commissioning/task/export/${locationId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      },
      responseType: 'blob' // Importante para receber dados binários
    });

    const blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;

    // Get filename from Content-Disposition header or use default
    const contentDisposition = response.headers['content-disposition'];
    const filename = contentDisposition?.split('filename=').at(-1)?.replace(/"/g, '')
      ?? 'tasks-export.xlsx';

    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();

    // Cleanup
    window.URL.revokeObjectURL(url);
    link.remove();
  } catch (error) {
    console.error('Error downloading file:', error);
    throw error;
  }
}

export async function getAssetCategories(instance) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.get(`${apiUrl}/maintenance/assetCategory`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getInstruments(instance, queryParams = {}) {
  const accessToken = await acquireToken(instance, scope);
  try {
    const { data } = await axios.get(`${apiUrl}/maintenance/instrument`, {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: { ...queryParams, uniqueValues: true },
    });

    return data;
  } catch (e) {
    throw e;
  }
}

export async function newInstrument(instance, item) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.post(`${apiUrl}/maintenance/instrument`, item, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function updateInstrument(instance, item) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.put(`${apiUrl}/maintenance/instrument/${item._id}`, item, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getOrgs(instance) {
  const accessToken = await acquireToken(instance, scope);
  try {
    const { data } = await axios.get(`${apiUrl}/settings/organization`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getDepartments(instance) {
  const accessToken = await acquireToken(instance, scope);
  try {
    const { data } = await axios.get(`${apiUrl}/settings/department`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function newDepartment(instance, department) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.post(`${apiUrl}/settings/department`, department, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function updateDepartment(instance, department) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.put(`${apiUrl}/settings/department/${department.id}`, department, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getSystems(instance, queryParams = {}) {
  const accessToken = await acquireToken(instance, scope);
  try {
    const { data } = await axios.get(`${apiUrl}/maintenance/system`, {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: queryParams,
    });
    return data;
  } catch (e) {
    throw e;
  }
}
export async function getSystemById(instance, id) {
  const accessToken = await acquireToken(instance, scope);
  try {
    const { data } = await axios.get(`${apiUrl}/maintenance/system/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function deleteSystem(instance, id) {
  const accessToken = await acquireToken(instance, scope);
  try {
    const { data } = await axios.delete(`${apiUrl}/maintenance/system/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getManufacturers(instance) {
  const accessToken = await acquireToken(instance, scope);
  try {
    const { data } = await axios.get(`${apiUrl}/settings/manufacturer`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function newManufacturer(instance, manufacturer) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.post(`${apiUrl}/settings/manufacturer`, manufacturer, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function updateManufacturer(instance, manufacturer) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.put(`${apiUrl}/settings/manufacturer/${manufacturer.id}`, manufacturer, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getAllAssetsByManufacturer(instance, queryParams) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.get(`${apiUrl}/settings/manufacturer/${queryParams.manufacturer}/assets`, {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: queryParams,
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getInterconnectionAndUniques(instance, queryParams = {}) {
  const accessToken = await acquireToken(instance, scope);
  try {
    const { data } = await axios.get(
      `${apiUrl}/maintenance/interconnection`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
        params: { ...queryParams, uniqueValues: true },
      },
    );
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getInterconnectionById(instance, id) {
  const accessToken = await acquireToken(instance, scope);
  try {
    const { data } = await axios.get(
      `${apiUrl}/maintenance/interconnection/${id}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );
    return data;
  } catch (e) {
    throw e;
  }
}


export async function getInterconnectionCategoryById(instance, id) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.get(`${apiUrl}/settings/interconnectionCategory/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function uploadInterconnectionXlsx(instance, files, { categoryId, costCenterId, locationId }) {
  const accessToken = await acquireToken(instance, scope);
  try {

    const formData = new FormData();

    files.forEach((file) => {
      formData.append("file", file);
    });

    formData.append("categoryId", categoryId)
    formData.append("costCenterId", costCenterId)
    formData.append("locationId", locationId)

    const { data } = await axios.post(
      `${apiUrl}/maintenance/interconnection/createFromFile`,
      formData,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      },
    );
    return data;
  } catch (e) {
    throw e;
  }
}

export async function newInterconnection(instance, item) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.post(`${apiUrl}/maintenance/interconnection`, item, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function updateInterconnection(instance, item) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.put(`${apiUrl}/maintenance/interconnection/${item.id ?? item}`, item, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

// acredito que seja apenas mudar queryParams na getInterconnectionAndUniques
// export async function getInterconnections(instance, positionId) {
//   const accessToken = await acquireToken(instance, scope);
//   try {
//     const { data } = await axios.get(
//       `${apiUrl}/commissioning/interconnection/getFromPosition/${positionId}`,
//       {
//         headers: { Authorization: `Bearer ${accessToken}` },
//       },
//     );
//     return data;
//   } catch (e) {
//     throw e;
//   }
// }

export async function getCostCenters(instance) {
  const accessToken = await acquireToken(instance, scope);
  try {
    const { data } = await axios.get(`${apiUrl}/settings/costcenter`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function newCostCenter(instance, costCenter) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.post(`${apiUrl}/settings/costcenter`, costCenter, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function updateCostCenter(instance, costCenter) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.put(`${apiUrl}/settings/costcenter/${costCenter.id}`, costCenter, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

// interconnection category
export async function getCategories(instance) {
  const accessToken = await acquireToken(instance, scope);
  try {
    const { data } = await axios.get(`${apiUrl}/settings/interconnectionCategory`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}


export async function getAssetCategoryById(instance, id) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.get(`${apiUrl}/maintenance/assetCategory/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getAssetById(instance, id) {
  const accessToken = await acquireToken(instance, scope);
  try {
    const { data } = await axios.get(`${apiUrl}/maintenance/asset/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function newSystem(instance, item) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.post(`${apiUrl}/maintenance/system`, item, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function updateSystem(instance, item) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.put(`${apiUrl}/maintenance/system/${item.id}`, item, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    console.error('Error in updateAsset:', e.message);
    throw e;
  }
}

export async function updateAsset(instance, assetId, payload) {
  try {
    const accessToken = await acquireToken(instance, scope);

    const { data } = await axios.put(`${apiUrl}/maintenance/asset/${assetId}`, payload, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    console.error('Error in updateAsset:', e.message);
    throw e;
  }
}

export async function getSystemChildrenDetails(instance, queryParams) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.get(`${apiUrl}/maintenance/system/${queryParams.system}/children`, {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: queryParams,
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getWoAndUniques(instance, queryParams = {}) {
  const accessToken = await acquireToken(instance, scope);
  try {
    const { data } = await axios.get(
      `${apiUrl}/commissioning/workOrder`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
        params: { ...queryParams, uniqueValues: true },
      },
    );
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getWoById(instance, id) {
  const accessToken = await acquireToken(instance, scope);
  try {
    const { data } = await axios.get(`${apiUrl}/commissioning/workOrder/${id}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function createWo(instance, wo) {
  const accessToken = await acquireToken(instance, scope);
  console.log(wo);
  try {
    const { data } = await axios.post(`${apiUrl}/commissioning/workOrder/`, wo, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}