import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import useGlobalState from '../../../Template/atoms/variables';
import { useTranslation } from 'react-i18next';
import './style.css';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const Modal = () => {
  const {
    modalTitle,
    modalOpen,
    setModalOpen,
    modalContent,
    setModalContent,
    modalConfirm,
    cleanOnCancel,
    setCleanOnCancel,
    modalDisabled,
    modalStyle,
  } = useGlobalState();
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    setModalOpen(false);
  }, [location.pathname]);

  return (
    <div className={`modalFullScreenWrapper ${modalOpen ? 'closed' : ''}`}>
      <div className='modalBodyContainer' style={modalStyle ?? {}}>
        <div className='modalHeader'>
          <div className='modalTitle'>{modalTitle}</div>
          <div className='modalClose'>
            <CloseOutlined
              className='closeIcon'
              onClick={async () => {
                if (cleanOnCancel) {
                  setModalContent(null);
                  setCleanOnCancel(false);
                }
                setModalOpen(false);
              }}
            />
          </div>
        </div>

        <div className='modalContent'>
          {Array.isArray(modalContent)
            ? modalContent?.map((content) => (
                <div key={content.key} className='modalField'>
                  {content.label && <div className='modalFieldLabel'>{content.label}</div>}
                  <div className='modalFieldNode'>{content.node}</div>
                </div>
              ))
            : modalContent}
        </div>
        {modalConfirm !== null && (
          <div className='modalFooter'>
            <Button
              type='text'
              onClick={async () => {
                if (cleanOnCancel) {
                  setModalContent(null);
                  setCleanOnCancel(false);
                }
                setModalOpen(false);
              }}
            >
              {t('cancel-btn')}
            </Button>
            <Button
              disabled={modalDisabled}
              style={{ background: modalDisabled ? 'grey' : 'black', color: 'white' }}
              onClick={modalConfirm}
            >
              {t('confirm-btn')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
