import { Outlet, useNavigate } from 'react-router-dom';
import { notification } from 'antd';

import useTemplateState from '../../../../Template/atoms/variables';
import { useEffect } from 'react';

const ExecutorsOutlet = () => {
  const navigate = useNavigate();

  const { setPendingApiCalls } = useTemplateState();

  useEffect(() => {
    triggerApiCalls();
  }, []);

  const triggerApiCalls = async () => {
    try {
    } finally {
    }
  };

  return <Outlet />;
};

export default ExecutorsOutlet;
