import { useTranslation } from 'react-i18next';
import CrudPage from '../../../../Template/layoutComponents/CrudPage';

import { getUserGroups, newUserGroup, updateUserGroup } from '../../../helpers/apiCalls';

import './style.css';

const UserGroups = () => {
  const { t } = useTranslation();

  return (
    <div id='organizationsPage'>
      <CrudPage
        title={t('user_group')}
        newText={t('new_user_group')}
        detailsLink={'/settings/user-groups'}
        defaultItem={{
          new: true,
          label: t('new_user_group'),
          properties: [
            {
              label: t('name'),
              type: 'text',
              value: t('new_user_group'),
              main: true,
              required: true,
              disabled: false,
              key: 'name',
            },
            {
              label: t('description'),
              type: 'text',
              value: '',
              main: true,
              required: true,
              disabled: false,
              key: 'description',
            },
            {
              label: t('access_level'),
              type: 'singleSelect',
              value: '',
              options: [
                { id: 'default', label: t('default') },
                { id: 'admin', label: t('admin') },
                { id: 'planner', label: t('planner') },
                { id: 'management', label: t('management') },
                { id: 'quality', label: t('quality') },
                { id: 'executor', label: t('executor') },
              ],
              main: true,
              required: true,
              disabled: false,
              key: 'access_level',
            },
          ],
        }}
        getAllItems={getUserGroups}
        newItem={newUserGroup}
        updateItem={updateUserGroup}
      />
    </div>
  );
};

export default UserGroups;
