import axios from 'axios';
import { protectedResourcesScopes } from '../../auth/config';
import { acquireToken } from '../../auth/msal.js';

const apiUrl = `${process.env.REACT_APP_BFF_API}`;
const scope = protectedResourcesScopes.core;

export async function getHierarchy(instance) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.get(`${apiUrl}/maintenance/hierarchy`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return data;
  } catch (e) {
    throw e;
  }
}

export async function saveHierarchy(instance, hierarchyLevels) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.post(`${apiUrl}/maintenance/hierarchy`, hierarchyLevels, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getInterconnectionCategories(instance) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.get(`${apiUrl}/settings/interconnectionCategory`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function newInterconnectionCategory(instance, category) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.post(`${apiUrl}/settings/interconnectionCategory`, category, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function updateInterconnectionCategory(instance, category) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.put(`${apiUrl}/settings/interconnectionCategory/${category.id}`, category, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getAllCategories(instance) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.get(`${apiUrl}/maintenance/category`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function newCategory(instance, category) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.post(`${apiUrl}/maintenance/category`, category, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function updateCategory(instance, category) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.put(`${apiUrl}/maintenance/category/${category.id}`, category, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getInstrumentCategories(instance) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.get(`${apiUrl}/settings/instrumentCategory`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function newInstumentCategory(instance, category) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.post(`${apiUrl}/settings/instrumentCategory`, category, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function updateInstrumentCategory(instance, category) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.put(`${apiUrl}/settings/instrumentCategory/${category.id}`, category, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getCommissioningStages(instance, categoryId) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.get(`${apiUrl}/commissioning/stages/${categoryId}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function newOrganization(instance, organization) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.post(`${apiUrl}/settings/organization`, organization, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function updateOrganization(instance, organization) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.put(`${apiUrl}/settings/organization/${organization.id}`, organization, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getUsers(instance, queryParams = {}) {
  const accessToken = await acquireToken(instance, scope);
  try {
    const { data } = await axios.get(`${apiUrl}/settings/user`, {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: queryParams,
    });

    return data;
  } catch (e) {
    throw e;
  }
}

export async function getExecutors(instance, queryParams = {}) {
  const accessToken = await acquireToken(instance, scope);
  try {
    const { data } = await axios.get(`${apiUrl}/settings/user/executors`, {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: queryParams,
    });

    console.log(data);

    return data;
  } catch (e) {
    throw e;
  }
}

export async function newUser(instance, user) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.post(`${apiUrl}/settings/user`, user, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function deleteUsers(instance, ids) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.post(`${apiUrl}/settings/user/deleteMany`, { _id: ids }, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function updateUser(instance, user) {


  try {
    const accessToken = await acquireToken(instance, scope);
    console.log('user', user);
    const { data } = await axios.put(`${apiUrl}/settings/user/${user.id}`, user, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getUserGroups(instance, queryParams = {}) {
  const accessToken = await acquireToken(instance, scope);
  try {
    const { data } = await axios.get(`${apiUrl}/settings/user/group`, {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: queryParams,
    });

    return data;
  } catch (e) {
    throw e;
  }
}

export async function newUserGroup(instance, userGroup) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.post(`${apiUrl}/settings/user/group`, userGroup, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}

export async function updateUserGroup(instance, userGroup) {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.put(`${apiUrl}/settings/user/group/${userGroup.id}`, userGroup, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
    return data;
  } catch (e) {
    throw e;
  }
}