import Checkbox from '../Checkmark';
import Date from '../Date';
import Input from '../Input';
import Select from '../SingleSelect';
import MultiSelect from '../MultiSelect';

import { useTranslation } from 'react-i18next';
import './style.css';

const Forms = ({
  id,
  inputs,
  onInputBlur,
  onInputChange,
  categories,
  departments,
  orgs,
  systems,
  manufacturers,
  costcenters,
}) => {
  const { t } = useTranslation();

  const concatDynamicOptions = (input) => {
    if (input.main) {
      const mappedKey = input.key;
      switch (mappedKey) {
        case 'category':
          return categories || [];
        case 'department':
          return departments || [];
        case 'organization':
          return orgs || [];
        case 'system':
          return systems || [];
        case 'manufacturer':
          return manufacturers || [];
        case 'cost_center':
          return costcenters || [];
        default:
          return [];
      }
    }
    return [];
  };

  const handleInputType = (input, index) => {
    switch (input?.type) {
      case 'text':
        return (
          <Input
            maxLength={input?.maxLength}
            key={`${id}-input-${index}-${input?.type}-${input.label}`}
            placeholder={typeof input.value === 'function' ? input.value() : input.value}
            type={'text'}
            onBlur={onInputBlur}
            value={typeof input.value === 'function' ? input.value() : input.value}
            disabled={input.disabled}
            onChange={(e) => {
              onInputChange(e, index);
            }}
          />
        );
      case 'number':
        return (
          <Input
            maxLength={input?.maxLength}
            key={`${id}-input-${index}-${input?.type}-${input.label}`}
            placeholder={input.value}
            type={'number'}
            value={input.value}
            disabled={input.disabled}
            onChange={(e) => {
              onInputChange(e, index);
            }}
          />
        );
      case 'singleSelect':
        return (
          <Select
            key={`${id}-input-${index}-${input?.type}-${input.label}-${JSON.stringify(input.value)}`}
            value={input.value}
            options={[
              ...(Array.isArray(concatDynamicOptions(input)) ? concatDynamicOptions(input) : []),
              ...(Array.isArray(input.options) ? input.options : []),
            ]}
            disabled={input.disabled}
            onChange={(e) => {
              onInputChange(e, index);
            }}
          />
        );
      case 'multiSelect':
        return (
          <MultiSelect
            key={`${id}-input-${index}-${input?.type}-${input.label}`}
            multi={true}
            value={input.value}
            options={(concatDynamicOptions(input) || []).concat(input.options)}
            disabled={input.disabled}
            onChange={(e) => {
              onInputChange(e, index);
            }}
          />
        );
      case 'bool':
        return (
          <Checkbox
            key={`${id}-input-${index}-${input?.type}-${input.label}`}
            checked={input.value}
            label=''
            disabled={input.disabled}
            onChange={(e) => {
              onInputChange(e, index);
            }}
          />
        );
      case 'date':
        console.log('input', input);
        return (
          <Date
            key={`${id}-input-${index}-${input?.type}-${input.label}`}
            value={input.value}
            disabled={input.disabled}
            onChange={(e) => {
              onInputChange(e, index);
            }}
          />
        );
    }
  };

  return (
    <div className='formsBody'>
      {inputs?.map((input, index) => {
        return (
          <div key={`${input.label}-${index}`} className='formsInputContainer'>
            <div className='formsInputLabel'>{t(input.key) || input.label}:</div>
            <div className='formsInput'>{handleInputType(input, index)}</div>
          </div>
        );
      })}
    </div>
  );
};

export default Forms;
