import React from 'react';
import ReactDOM from 'react-dom/client';

import reportWebVitals from './reportWebVitals';
import Routes from './routes';
import axios from 'axios';

import { msalInstance } from './auth/msal';
import { MsalProvider } from '@azure/msal-react';
import { RecoilRoot } from 'recoil';

import Clarity from '@microsoft/clarity';

import './index.css';

Clarity.init(process.env.REACT_APP_CLARITY_PROJECT_ID)

axios.defaults.withCredentials = true;
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <MsalProvider instance={msalInstance}>
    <RecoilRoot>
      <Routes />
    </RecoilRoot>
  </MsalProvider>,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
