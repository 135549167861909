import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import useGlobalState from '../../Template/atoms/variables';
import { InnerSiderControll } from '../../Template/layoutComponents/InnerSiderControll';
import { useTranslation } from 'react-i18next';
import ChatsComponent from '../components/SiderChats/ChatsComponent';
import { getEquipmentCategories } from '../helpers/category';
import { useMsal } from '@azure/msal-react';

import { notification } from 'antd';

import useTemplateState from '../../Template/atoms/variables';

import useVirtualEngineerState from '../atoms/variables';
import { getLocations } from '../helpers/location';

export const VirtualEngineeringOutlet = () => {
  const { t } = useTranslation();
  const { setTitle, siderVisible, setPendingApiCalls } = useGlobalState();

  const { user } = useTemplateState();
  const {
    setEquipmentCategories,
    setLocations,
    setCurrentCategory,
    setConversations,
    setCurrentThreadId,
    setCurrentEquipment,
    setCurrentLocationId,
    setCurrentEquipmentId,
    setFavorites,
  } = useVirtualEngineerState();

  const { instance } = useMsal();
  const [notificationApi, contextHolder] = notification.useNotification();

  useEffect(() => {
    triggerApiCalls();
    return () => {
      setCurrentCategory(null);
      setConversations([]);
      setCurrentThreadId(null);
      setCurrentEquipment(null);
      setCurrentEquipmentId(null);
      setCurrentLocationId(null);
      setFavorites([]);
    };
  }, []);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['getLocations', 'getEquipmentCategories']));

      const responses = await Promise.all([
        getLocations(instance),
        getEquipmentCategories(instance),
      ]);
      setLocations(responses[0] ?? []);
      setEquipmentCategories(responses[1] ?? []);
      setFavorites(user?.preferences?.favorites ?? []);

      const anyEmptyResponse = responses.some((response) => response.length === 0);

      if (anyEmptyResponse) {
        notificationApi.info({
          message: t('empty_database'),
          description: t('missing_items_description'),
          duration: 5,
        });
      }
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
      console.log(err);
    } finally {
      setPendingApiCalls((current) =>
        current.filter(
          (item) =>
            item !== 'getLocations' && item !== 'getEquipmentCategories' && item !== 'getUser',
        ),
      );
    }
  };

  useEffect(() => {
    setTitle(
      <InnerSiderControll
        breadcrumbs={[{ label: 'Virtual Engineer', link: '/virtual-engineer' }]}
      />,
    );
  }, [siderVisible]);

  return (
    <div className={`default-module-outlet ${siderVisible ? 'siderOpen' : 'siderClose'}`}>
      {siderVisible && <ChatsComponent collapsed={!siderVisible} />}
      {contextHolder}
      <Outlet />
    </div>
  );
};
