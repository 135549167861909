import { useEffect, useState } from 'react';
import DoubleColumnTemplate from '../../../../Template/layoutComponents/DoubleColumn';
import Tree from '../../../components/Tree';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import useSettingsState from '../../../atoms/variables';

import locationCategories from '../../../images/locationCategories.svg';
import assetCategories from '../../../images/assetCategories.svg';
import instrumentCategories from '../../../images/instrumentCategories.svg';
import interconnectionCategories from '../../../images/interconnectionCategories.svg';

import './style.css';

const AssetTreeManage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { defaultNodes, chosenPositions } = useSettingsState();

  const labelMapping = {
    plant: 'plant',
    area: 'area',
    'sub-area': 'sub_area',
    line: 'line',
    machine: 'machine',
    assembly: 'assembly',
    equipment: 'equipment',
    component: 'component',
  };

  const translateNodes = (nodes) => {
    return nodes?.map((node) => {
      const translationKey = labelMapping[node.type] || node.label.toLowerCase();
      return {
        ...node,
        label: t(translationKey),
      };
    });
  };

  const translatedDefaultNodes = translateNodes(defaultNodes);

  const handleTreeNodeClick = (node) => {
    if (node.kind === 'position') {
      navigate('/settings/asset-tree/manage/positions', { state: { selectedNode: node } });
    } else {
      navigate('/settings/asset-tree/manage/assets', { state: { selectedNode: node } });
    }
  };

  const gridItems = [
    {
      img: locationCategories,
      title: t('manage_position_categories'),
      subtitle: t('manage_position_categories_subtitle'),
      buttonText: t('position_categories_button'),
      buttonLink: '/settings/asset-tree/manage/positions',
    },
    {
      img: interconnectionCategories,
      title: t('manage_interconnection_categories'),
      subtitle: t('manage_interconnection_categories_subtitle'),
      buttonText: t('interconnection_categories_button'),
      buttonLink: '/settings/asset-tree/manage/interconnections',
    },
    {
      img: assetCategories,
      title: t('manage_asset_categories'),
      subtitle: t('manage_asset_categories_subtitle'),
      buttonText: t('asset_categories_button'),
      buttonLink: '/settings/asset-tree/manage/assets',
    },
    {
      img: instrumentCategories,
      title: t('manage_instrument_categories'),
      subtitle: t('manage_instrument_categories_subtitle'),
      buttonText: t('instrument_categories_button'),
      buttonLink: '/settings/asset-tree/manage/instruments',
    },
  ];

  return (
    <div id='assetTree'>
      <DoubleColumnTemplate
        columnColors={['#FCFCFC', '#FFFFFF']}
        firstColumnContent={
          <div className='columnContainer' style={{ overflow: 'auto' }}>
            <div className='columnHeader' style={{ alignItems: 'center' }}>
              <div className='columnTitle'>{t('asset_tree')}</div>
            </div>
            <Tree
              disabled={false}
              treeNodes={
                chosenPositions
                  ? (translateNodes(chosenPositions.filter((node) => node.kind === 'position')) ??
                    [])
                  : translatedDefaultNodes.filter((node) => node.kind === 'position')
              }
              maxNodeWidth='90%'
              nodeType='positions'
              chosenPositions={[]}
              setChosenPosition={(node) => handleTreeNodeClick(node)}
            />
            <div className='doubleTreeSpacerContainer'>
              <div className='treeSpacer' style={{ position: 'absolute', height: '4.4rem' }}></div>
            </div>
            <Tree
              disabled={false}
              treeNodes={
                chosenPositions
                  ? (translateNodes(chosenPositions.filter((node) => node.kind === 'asset')) ?? [])
                  : translatedDefaultNodes.filter((node) => node.kind === 'asset')
              }
              maxNodeWidth={`${90 - (chosenPositions ? chosenPositions : defaultNodes)?.filter((node) => node.kind === 'position').length * 5}%`}
              nodeType='assets'
              chosenPositions={[]}
              setChosenPosition={(node) => handleTreeNodeClick(node)}
            />
          </div>
        }
        secondColumnContent={
          <div className='columnContainer' style={{ overflow: 'auto' }}>
            <div className='manageCategoriesGrid'>
              {gridItems.map((gridItem, index) => {
                return (
                  <div className='manageCategoriesGridItem'>
                    <div className='manageCategoriesGridItemImg'>
                      <img src={gridItem.img} />
                    </div>
                    <div className='manageCategoriesGridItemText'>
                      <div className='manageCategoriesGridTitle'>{gridItem.title}</div>
                      <div className='manageCategoriesGridSubtitle'>{gridItem.subtitle}</div>
                    </div>
                    <div
                      className='manageCategoriesGridItemButton'
                      onClick={() => {
                        navigate(gridItem.buttonLink);
                      }}
                    >
                      <Button
                        size='large'
                        style={{
                          background: '#1777FF',
                          color: 'white',
                        }}
                      >
                        {gridItem.buttonText}
                      </Button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        }
      />
    </div>
  );
};

export default AssetTreeManage;
