import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { notification } from 'antd';
import useGlobalState from '../../../Template/atoms/variables';
import { useEffect } from 'react';
import { sleep } from '../../../helpers/misc';
import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';
import { getExistingAssetTrees } from '../../helpers/apiCalls';
import useMaintenanceState from '../../atoms/variables';

const PlantsOutlet = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { plantId } = useParams();
  const { instance } = useMsal();
  const [notificationApi, contextHolder] = notification.useNotification();

  const { setPendingApiCalls } = useGlobalState();
  const { setExistingAssetTrees, setChosenPlant } = useMaintenanceState();

  useEffect(() => {
    triggerApiCalls();
  }, []);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['getAssetById']));

      const response = await getExistingAssetTrees(instance);
      const currentPlant = response.find((plant) => {
        return plant.id === plantId;
      });

      setExistingAssetTrees(response);
      setChosenPlant(currentPlant);
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'getAssetById'));
    }
  };

  return (
    <>
      {contextHolder}
      <Outlet />
    </>
  );
};

export default PlantsOutlet;
