import Table from '../Table';
import { useTranslation } from 'react-i18next';
/**
 * Componente que renderiza as fases do relatório
 * Suporta diferentes tipos de fase (form e camera)
 *
 * @component
 * @param {Object} props
 * @param {Array} props.phases - Array de fases do relatório
 * @param {boolean} props.editing - Indica se está em modo de edição
 * @param {Function} props.onValueChange - Callback para alterações nos valores
 * @param {Function} props.unit - Unidade de medida para campos de leitura
 */
const ContentPhases = ({ phases, editing, onValueChange, unit }) => {
  const { t } = useTranslation();
  // Processa fases do tipo form
  const renderFormPhase = (phase, phaseIndex) => {
    return (
      <Table
        key={phaseIndex}
        title={phase.title}
        editing={editing}
        content={phase.fields.map((field, fieldIndex) => ({
          title: field.label,
          value: field.userGivenValue ? field.userGivenValue[0] : '-',
          type: field.type,
          choices: field.choices,
          index: fieldIndex,
          valid: field.isGoodToGo,
        }))}
        onValueChange={(fieldIndex, newValue) => onValueChange(phaseIndex, fieldIndex, newValue)}
      />
    );
  };

  const mapMeggerContent = (step, stepIndex) => {
    return {
      title: step.title,
      value: {
        reading: { value: step.imageAnalysis?.reading },
        reading_unit: {
          value: step.imageAnalysis?.reading_unit,
          type: 'select',
          choices: unit?.map((u) => {
            return { id: u.symbol, label: u.symbol };
          }),
        },
      },
      valid: step.valid,
      index: stepIndex,
    };
  };
  const mapHipotContent = (step, stepIndex) => {
    return Object.entries(step.imageAnalysis)
      .map(([key, value]) => {
        if (key !== 'valid' && key !== 'operator') {
          return {
            title: t(key) ?? key,
            value: value,
            valid: key === 'time_count' ? step.valid : true,
            index: stepIndex,
            key,
          };
        }
      })
      .filter((item) => Boolean(item));
  };

  // Processa fases do tipo camera
  const renderCameraPhase = (phase, phaseIndex) => {
    const content = phase.steps
      .map((step, stepIndex) => {
        if (step.imageAnalysis) {
          if (step.imageAnalysis?.reading && step.imageAnalysis?.reading_unit) {
            return mapMeggerContent(step, stepIndex);
          }
          return mapHipotContent(step, stepIndex);
        }
      })
      .filter((item) => Boolean(item))
      .flat();
    return (
      content.length > 0 && (
        <Table
          key={phaseIndex}
          title={phase.title}
          editing={editing}
          content={content}
          onValueChange={(fieldIndex, newValue, field) =>
            onValueChange(phaseIndex, fieldIndex, newValue, field)
          }
        />
      )
    );
  };

  return phases.map((phase, phaseIndex) => {
    if (phase.type === 'form') return renderFormPhase(phase, phaseIndex);
    if (phase.type === 'camera') return renderCameraPhase(phase, phaseIndex);
    return null;
  });
};

export default ContentPhases;
