const createColumns = (selectedRowKeys, handleSelect, currentEquipment, setDeselectedKeys) => {
  const columns = [
    {
      title: 'Ativos',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Categoria',
      dataIndex: 'category',
      key: 'category',
      sorter: (a, b) => a.category.localeCompare(b.category),
    },
    {
      title: 'Fabricante',
      dataIndex: 'manufacturer',
      key: 'manufacturer',
      sorter: (a, b) => a.manufacturer.localeCompare(b.manufacturer),
    },
    {
      title: 'Planta',
      dataIndex: 'location',
      key: 'location',
      sorter: (a, b) => a.location.localeCompare(b.location),
    },
  ];

  return { columns };
};

export default createColumns;
