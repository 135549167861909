import ReactDOM from 'react-dom';
import { isDivOutOfViewport } from '../../../../helpers/misc';

const TableCellFilterWrapper = ({ showFilter, children, name, isOpen, position, onClose }) => {
  if (!isOpen || showFilter !== name) return null;

  const portalStyle = {
    position: 'absolute',
    top: position.top - 15,
    left: isDivOutOfViewport(document.getElementById(`opwellTableCellControllFilter-${name}`), 400)
      ? position.left - convertRemToPixels(21.5)
      : position.left,
    zIndex: 9,
  };

  function convertRemToPixels(rem) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
  }

  return ReactDOM.createPortal(
    <div
      key={`${showFilter}-${isOpen}`}
      style={{
        ...portalStyle,
        paddingLeft: '1rem',
        paddingRight: isDivOutOfViewport(
          document.getElementById(`opwellTableCellControllFilter-${name}`),
        )
          ? '1rem'
          : '',
      }}
      onMouseLeave={onClose}
    >
      <div id={`opwellTableCellFilterWrapper-${name}`} className='opwellTableCellFilterWrapper'>
        {children}
      </div>
    </div>,
    document.body,
  );
};

export default TableCellFilterWrapper;
