import React from 'react';

import manufacturer from '../../../../../images/manufacturer.svg';

const InstrumentsIconComponent = ({
  height = '14px',
  width = height,
  altText = 'icon',
  selected,
}) => {
  return <img src={manufacturer} alt={altText} style={{ width, height }} />;
};

export default InstrumentsIconComponent;
