import Select from '../../../../../../../../../../../Template/layoutComponents/SingleSelect';
import moment from 'moment';

const VersionSelector = ({ versions, currentVersion, onVersionChange }) => {
  const sortedVersions = versions?.sort((a, b) => b.version - a.version);

  return (
    <div
      style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '20px' }}
      key={currentVersion}
    >
      <span style={{ color: '#225968', fontWeight: '500' }}>Versão:</span>
      <Select
        value={{ id: currentVersion }}
        onChange={onVersionChange}
        style={{ width: '300px' }}
        options={[
          ...(sortedVersions?.map((v) => ({
            id: v.version,
            label:
              v.author === 'original'
                ? `Original - ${moment(v.date).format('DD/MM/YYYY HH:mm')}`
                : `V${v.version} - ${moment(v.date).format('DD/MM/YYYY HH:mm')} - ${v.author}`,
          })) || []),
        ]}
      />
    </div>
  );
};

export default VersionSelector;
