import { acquireToken } from '../../auth/msal.js';
import { protectedResourcesScopes } from '../../auth/config.js';
import axios from 'axios';

const apiUrl = `${process.env.REACT_APP_BFF_API}`;
const scope = protectedResourcesScopes.core;

export const getLocations = async (instance) => {
  try {
    const accessToken = await acquireToken(instance, scope);
    const { data } = await axios.get(`${apiUrl}/maintenance/position`, {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: { limit: 100 },
      // params: { parent: 'null' },
    });
    return data.items ?? data;
  } catch (e) {
    throw e;
  }
};
