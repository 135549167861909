import Table from '../../../../../Template/layoutComponents/Table';
import { useTranslation } from 'react-i18next';

import useTemplateState from '../../../../../Template/atoms/variables';
import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, notification } from 'antd';
import { getUsers, updateUser } from '../../../../helpers/apiCalls';
import useSettingsState from '../../../../atoms/variables';
import { PlusOutlined } from '@ant-design/icons';

import './style.css';
import AddUsersModal from './addUsersModal';

const AllUsersTab = () => {
  const { instance } = useMsal();
  const { categoryId } = useParams();

  const navigate = useNavigate();

  const { t } = useTranslation();
  const [notificationApi, contextHolder] = notification.useNotification();

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    triggerApiCalls();
  }, []);

  const {
    setPendingApiCalls,
    setModalOpen,
    setModalStyle,
    setModalTitle,
    setModalContent,
    setModalConfirm,
    setCleanOnCancel,
  } = useTemplateState();
  const { viewDetailsCategory, setViewDetailsCategory } = useSettingsState();

  const [users, setUsers] = useState([]);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['getAllusers']));

      const response = await Promise.all([getUsers(instance, { user_group: categoryId })]);

      setUsers(response[0]);
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'getAllusers'));
    }
  };

  async function getUserName(row) {
    const userAccount = instance.getAccountByHomeId(row.user_id)?.idTokenClaims;
    if (userAccount) {
      return userAccount.name ?? `${userAccount.given_name} ${userAccount.family_name}`;
    }
    if (row.properties?.find((p) => p.key === 'name')) {
      return row.properties.find((p) => p.key === 'name').value;
    }
    return row.email;
  }

  return (
    <div className='allUsersTab'>
      {contextHolder}
      <div className='instrumentsContentHeader'>
        <div className='pageTitle'>{t('users')}</div>
        <div className='pageButtons'>
          <Button
            id='cancel'
            className='newItemButton'
            size='large'
            disabled={selectedUsers.length === 0}
            style={{
              borderColor: 'black',
              background: 'white',
              color: 'black',
              opacity: selectedUsers.length > 0 ? 1 : 0.5,
            }}
            onClick={async () => {
              try {
                setPendingApiCalls((current) => current.concat(['updateUser']));

                const promises = selectedUsers.map((u) => {
                  return updateUser(instance, {
                    ...u,
                    user_group: u.user_group.filter((g) => g != categoryId),
                    properties: u.properties.map((prop) => {
                      if (prop.key === 'user_group') {
                        return { ...prop, value: prop.value?.filter((v) => v?.id != categoryId) };
                      } else return prop;
                    }),
                  });
                });

                await Promise.all(promises);

                setUsers((current) => ({
                  ...current,
                  items: current.items.filter(
                    (u) => !selectedUsers.some((selected) => selected.id === u.id),
                  ),
                }));

                setSelectedUsers([]);
                setReload(true);
              } catch (err) {
                notificationApi.error({
                  message: t('database_communication_failure'),
                  description: err?.response?.data?.message
                    ? `${err?.response?.data?.message} (${err?.response?.status})`
                    : err?.message,
                  duration: 5,
                });
              } finally {
                setPendingApiCalls((current) => current.filter((item) => item !== 'updateUser'));
              }
            }}
          >
            {t('remove')}
          </Button>

          <Button
            className='newItemButton'
            size='large'
            icon={<PlusOutlined />}
            style={{
              borderColor: '#1777FF',
              background: '#1777FF',
              color: 'white',
            }}
            onClick={async () => {
              setModalTitle(t('add_users'));
              setModalContent(<AddUsersModal setReload={setReload} />);
              setModalStyle({ padding: '2rem', gap: 0, height: '100%' });
              setCleanOnCancel(true);
              setModalConfirm(null);
              setModalOpen(true);
            }}
          >
            {t('new_user')}
          </Button>
        </div>
      </div>
      <Table
        handleSelect={(selected) => {
          setSelectedUsers(selected);
        }}
        apiCall={(instance, params) => getUsers(instance, { user_group: categoryId, ...params })}
        items={users?.items}
        handleRowClick={(row) => {
          setViewDetailsCategory({
            ...row,
            id: row.id,
            name: getUserName(row),
            label: getUserName(row),
          });
          navigate(`/settings/users/${row.id}`);
        }}
        tableSettings={{
          totalItems: users?.totalItems ?? 0,
          columnsConfig: {
            email: {
              label: t('email'),
            },
          },
          sortable: ['email'],
          listFilter: [],
          searchFilter: ['email'],
          uniqueValues: users?.uniqueValues,
        }}
        forceReload={reload}
        setForceReload={setReload}
      />
    </div>
  );
};

export default AllUsersTab;
