import { useTranslation } from 'react-i18next';

import Table from '../../../../../../Template/layoutComponents/Table';
import TableTip from '../../../../../../Template/layoutComponents/Table/components/tip';
import { useEffect, useState } from 'react';
import { notification } from 'antd';
import { getInterconnectionAndUniques } from '../../../../../helpers/apiCalls';
import { useMsal } from '@azure/msal-react';
import useTemplateState from '../../../../../../Template/atoms/variables';
import { getInterconnectionCategories } from '../../../../../../Settings/helpers/apiCalls';
import { CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';

const InterconnectionsTab = ({ action, children }) => {
  const { t } = useTranslation();
  const [notificationApi, contextHolder] = notification.useNotification();
  const { instance } = useMsal();
  const { plantId } = useParams();
  const { setPendingApiCalls } = useTemplateState();
  const [interconnections, setInterconnections] = useState({});

  const [categories, setCategories] = useState({});

  useEffect(() => {
    triggerApiCall();
  }, []);

  const triggerApiCall = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['getInterconnectionAndUniques']));

      const responses = await Promise.all([
        getInterconnectionAndUniques(instance, { location: plantId, limit: 10 }),
        getInterconnectionCategories(instance),
      ]);

      setInterconnections(responses[0]);
      setCategories(responses[1]);
    } catch (err) {
      // API fail notification
      notificationApi.error({
        message: 'Não foi possível recuperar todas as informações necessárias',
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
    } finally {
      setPendingApiCalls((current) =>
        current.filter((item) => item !== 'getInterconnectionAndUniques'),
      );
    }
  };

  return (
    <div className='columnContainer' style={{ minHeight: '50vh', gap: '1rem', overflow: 'auto' }}>
      {contextHolder}
      <div className='columnHeader'>
        <div className='columnTitle'>{t('interconnections')}</div>
      </div>
      <div style={{ overflow: 'visible' }}>
        <Table
          handleSelect={null}
          handleRowClick={null}
          apiCall={async (instance, params) => {
            const response = await getInterconnectionAndUniques(instance, {
              ...params,
              location: plantId,
            });

            return {
              ...response,
              items: response.items.map((item) => ({ ...item, add: 'placeholder' })),
            };
          }}
          items={interconnections?.items?.map((item) => ({ ...item, add: 'placeholder' }))}
          tableSettings={{
            totalItems: interconnections?.totalItems ?? 0,
            columnsConfig: {
              category: {
                label: t('category'),
                replaceValue: (value) => {
                  return categories?.find((cat) => cat._id === value || cat.id === value);
                },
              },
              name: {
                label: t('name'),
              },
              status: {
                label: t('status'),
                customElement: (item) => <TableTip children={item.status ?? item} />,
              },
              add: {
                label: '',
                customElement: (item) => (
                  <div className='addToSystem' onClick={() => action(item)}>
                    {children?.some((child) => child === item.id) ? (
                      <CloseCircleOutlined style={{ fontSize: ' 1.5rem', color: '#494949' }} />
                    ) : (
                      <PlusCircleOutlined style={{ color: '#686978' }} />
                    )}
                  </div>
                ),
              },
            },
            sortable: ['category', 'name'],
            listFilter: ['category'],
            searchFilter: ['name'],
            uniqueValues: interconnections?.uniqueValues,
          }}
        />
      </div>
    </div>
  );
};

export default InterconnectionsTab;
