import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import Tabs from '../../../../Template/layoutComponents/Tabs';

import HistoryTab from '../../../../Template/layoutComponents/HistoryTab';
import GeneralTab from '../../../../Template/layoutComponents/GeneralTab';
import { updateOrganization } from '../../../helpers/apiCalls';
import { notification } from 'antd';
import { useMsal } from '@azure/msal-react';
import useTemplateState from '../../../../Template/atoms/variables';
import useSettingsState from '../../../atoms/variables';
import { getOrgs } from '../../../../Maintenance/helpers/apiCalls';

import useMaintenanceState from '../../../../Maintenance/atoms/variables';

const Details = () => {
  const { t } = useTranslation();
  const [notificationApi, contextHolder] = notification.useNotification();
  const { instance } = useMsal();
  const { categoryId } = useParams();

  const { setPendingApiCalls } = useTemplateState();
  const { setOrgs } = useMaintenanceState();
  const { viewDetailsCategory, setViewDetailsCategory } = useSettingsState();

  useEffect(() => {
    if (!viewDetailsCategory) {
      triggerApiCalls();
    }
  }, []);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['getOrgs']));

      const response = await getOrgs(instance);
      setOrgs(response);
      setViewDetailsCategory(response?.find((o) => o.id === categoryId || o._id === categoryId));
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'getOrgs'));
    }
  };

  return (
    <div id='categoryDetails'>
      {contextHolder}
      <Tabs
        tabs={[
          {
            label: t('general_tab'),
            content: (
              <GeneralTab
                noEditing={true}
                detailsLink={'/settings/organizations'}
                updateItem={updateOrganization}
              />
            ),
            disabled: false,
          },
          {
            label: t('history_tab'),
            content: <HistoryTab id={categoryId} />,
            disabled: false,
          },
        ].filter((tab) => tab)}
      />
    </div>
  );
};

export default Details;
