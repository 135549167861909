import { useNavigate, useParams } from 'react-router-dom';
import Tabs from '../../../../Template/layoutComponents/Tabs';
import { notification } from 'antd';
import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';

import UploadDocumentPage from '../../../../Template/layoutComponents/UploadDocumentPage/index';

import { deleteFile, getFiles, saveFile } from '../../../helpers/files';

import GeneralTab from '../../../components/GeneralTab';

import './style.css';
import { getInstrumentCategories } from '../../../../Settings/helpers/apiCalls';
import { getInstruments, newInstrument, updateInstrument } from '../../../helpers/apiCalls';
import useMaintenanceState from '../../../atoms/variables';
import useTemplateState from '../../../../Template/atoms/variables';
import { useEffect } from 'react';

const InstrumentDetails = () => {
  const { t } = useTranslation();
  const { instrumentId } = useParams();

  const { instance } = useMsal();
  const [notificationApi, contextHolder] = notification.useNotification();

  const { setPendingApiCalls } = useTemplateState();
  const { chosenPlant, viewDetailsItem, setViewDetailsItem } = useMaintenanceState();

  useEffect(() => {
    if (
      !viewDetailsItem ||
      (instrumentId !== 'newInstrument' && !viewDetailsItem?.certification_date)
    ) {
      triggerApiCalls();
    }
  }, []);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['getInstruments']));

      const response = await getInstruments(instance, { _id: instrumentId });
      setViewDetailsItem(response?.items?.[0]);
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'getInstruments'));
    }
  };

  const updateCurrentEditingItem = (setEditing, allCategories, current, inputIndex, event) => {
    setEditing(true);
    let chosenCategory;
    // Search by current selected ID
    chosenCategory = allCategories?.find((cat) => {
      return cat.id === event.target.value;
    });

    // If not found, search by selected Categoy in 'Categoria' property
    if (!chosenCategory)
      chosenCategory = allCategories?.find((cat) => {
        const catProp = current.properties.find((prop) => prop?.key === 'category' && prop?.main);

        return cat.id === catProp?.value?.id;
      });

    const chosenCategoryProperties = chosenCategory ? [...chosenCategory?.properties] : [];
    const certifiable = chosenCategory?.certifiable;

    let updateName = false;

    return {
      ...current,
      properties: [
        ...current.properties.map((item, index) => {
          if (item.key === 'certification_date') {
            item = {
              ...item,
              value: certifiable ? item.value : null,
              disabled: !certifiable,
              required: certifiable,
            };
          }

          if (index === inputIndex) {
            if (item.label.toLowerCase() === 'nome') {
              updateName = event.target.value;
            }

            return {
              ...item,
              value: item.type.toLowerCase().includes('select')
                ? Array.isArray(event.target.value)
                  ? event.target.value.map((value) => ({
                      id: value,
                      label: '',
                    }))
                  : { id: event.target.value, label: '' }
                : item.type.toLowerCase().includes('bool')
                  ? event.target.value
                  : event.target.value,
              // event.target.value?.replace(/ /g, ''),
            };
          }
          return item;
        }),
      ]
        .filter(
          (prop) =>
            prop.main ||
            chosenCategoryProperties.some((currentCatProp) => currentCatProp.label == prop.label),
        )
        .concat(
          chosenCategory?.properties
            ?.filter((prop) => !prop.main)
            .filter(
              (prop) =>
                !current.properties.some(
                  (currentProp) => !currentProp.main && currentProp.label == prop.label,
                ),
            ) ?? [],
        ),
      name: updateName ? updateName : current.name,
    };
  };

  return (
    <div id='instrumentDetails'>
      <Tabs
        tabs={[
          {
            label: t('general_tab'),
            content: (
              <GeneralTab
                create={newInstrument}
                update={updateInstrument}
                getCategories={getInstrumentCategories}
                updateCurrentEditingItem={updateCurrentEditingItem}
                goBackTo='/maintenance/instruments'
              />
            ),
          },
          {
            label: t('documents_tab'),
            content: (
              <UploadDocumentPage
                id={instrumentId}
                type={'instrument'}
                getCall={getFiles}
                uploadCall={saveFile}
                deleteCall={deleteFile}
              />
            ),
            disabled: instrumentId === 'newInstrument',
          },
        ].filter((tab) => tab)}
        defaultOpen={0}
      />
    </div>
  );
};

export default InstrumentDetails;
