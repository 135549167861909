import plant from '../../images/plant.svg';
import plantSelected from '../../images/plantSelected.svg';
import interconnectionSystem from '../../images/interconnectionSystem.svg';
import area from '../../images/area.svg';
import areaSelected from '../../images/areaSelected.svg';
import subArea from '../../images/subArea.svg';
import subAreaSelected from '../../images/subAreaSelected.svg';
import line from '../../images/line.svg';
import lineSelected from '../../images/lineSelected.svg';
import machine from '../../images/machine.svg';
import machineSelected from '../../images/machineSelected.svg';
import machineSystem from '../../images/machineSystem.svg';
import assembly from '../../images/assembly.svg';
import assemblySelected from '../../images/assemblySelected.svg';
import assemblySystem from '../../images/assemblySystem.svg';
import equipment from '../../images/equipment.svg';
import equipmentSelected from '../../images/equipmentSelected.svg';
import equipmentSystem from '../../images/equipmentSystem.svg';
import component from '../../images/component.svg';
import componentSelected from '../../images/componentSelected.svg';
import componentSystem from '../../images/componentSystem.svg';

const icons = [
  {
    label: 'Interconexão',
    type: 'interconnection',
    icon: <img src={plant} />,
    iconSelected: <img src={interconnectionSystem} />,
    iconSystem: <img src={interconnectionSystem} />,
  },
  {
    label: 'Planta',
    type: 'plant',
    icon: <img src={plant} />,
    iconSelected: <img src={plantSelected} />,
    iconSystem: <img src={plantSelected} />,
  },
  {
    label: 'Area',
    type: 'area',
    icon: <img src={area} />,
    iconSelected: <img src={areaSelected} />,
    iconSystem: <img src={areaSelected} />,
  },
  {
    label: 'Sub-Area',
    type: 'sub-area',
    icon: <img src={subArea} />,
    iconSelected: <img src={subAreaSelected} />,
    iconSystem: <img src={subAreaSelected} />,
  },
  {
    label: 'Linha',
    type: 'line',
    icon: <img src={line} />,
    iconSelected: <img src={lineSelected} />,
    iconSystem: <img src={lineSelected} />,
  },
  {
    label: 'Máquina',
    type: 'machine',
    icon: <img src={machine} />,
    iconSelected: <img src={machineSelected} />,
    iconSystem: <img src={machineSystem} />,
  },
  {
    label: 'Conjunto',
    type: 'assembly',
    icon: <img src={assembly} />,
    iconSelected: <img src={assemblySelected} />,
    iconSystem: <img src={assemblySystem} />,
  },
  {
    label: 'Equipamento',
    type: 'equipment',
    icon: <img src={equipment} />,
    iconSelected: <img src={equipmentSelected} />,
    iconSystem: <img src={equipmentSystem} />,
  },
  {
    label: 'Componente',
    type: 'component',
    icon: <img src={component} />,
    iconSelected: <img src={componentSelected} />,
    iconSystem: <img src={componentSystem} />,
  },
];

export default icons;
