import Header from './components/Header';
import Table from './components/Table';
import ContentPhases from './components/ContentPhases';
import Evidences from './components/Evidences';
import Signatures from './components/Signatures';
import { useTranslation } from 'react-i18next';
import ReportTranslationService from '../../../../../../../../../../services/ReportTranslationService';
import { useMemo } from 'react';

// não sei se eu poderia ter usado isso aqui. talvez deveríamos colocar companyInfo no estado global?
import useTemplateState from '../../../../../../../../../../Template/atoms/variables';
import logo from '../../../../../../../../../../Template/images/logo.svg';
import moment from 'moment';
import './style.css';

/**
 * Componente principal do formulário de relatório
 * Gerencia a exibição e edição de relatórios com suporte a versionamento
 *
 * @component
 * @param {Object} props
 * @param {boolean} props.editing - Indica se o relatório está em modo de edição
 * @param {Object} props.report - Dados do relatório
 * @param {Function} props.setReport - Função para atualizar o relatório
 */
const ReportForms = ({ editing, report, handlePhaseValueChange, getVersionContent }) => {
  const { t, i18n } = useTranslation();
  const { companyInfo } = useTemplateState();

  // Usar useMemo para cachear a tradução e recalcular quando o idioma ou relatório mudar
  const translatedReport = useMemo(() => {
    return ReportTranslationService.translateReport(report, i18n.language);
  }, [report, i18n.language]);

  const generalInfo = useMemo(
    () => [
      {
        title: t('contractor') + ': ',
        value: report.employee.properties.find((p) => p.key === 'company').value,
        valid: true,
      },
      {
        title: t('date') + ':',
        value: report.updatedAt ? moment(report.updatedAt).format('DD/MM/YYYY') : 'N/A',
        valid: true,
      },
      { title: t('location') + ':', value: report.subject?.location?.name, valid: true },
      { title: t('name') + ':', value: report.subject?.name, valid: true },
      { title: t('token') + ':', value: report.token, valid: true },
      { title: t('attempt') + ':', value: report.attempt, valid: true },
      {
        title: t('origin') + ':',
        value: report.subject.properties.find((p) => p.key === 'origin')?.value,
        valid: true,
      },
      {
        title: t('destiny') + ':',
        value: report.subject.properties.find((p) => p.key === 'destiny')?.value,
        valid: true,
      },
    ],
    [t, report],
  ); // Dependências do generalInfo

  return (
    translatedReport && (
      <div className='reportBody'>
        <Header reportLogo={companyInfo?.logo ?? logo} report={translatedReport} />
        <div className='report-content'>
          <Table title={t('general_tab')} content={generalInfo} />
          <ContentPhases
            phases={
              ReportTranslationService.translateReport(
                { ...report, phase: getVersionContent() },
                i18n.language,
              ).phase
            }
            editing={editing}
            onValueChange={handlePhaseValueChange}
            unit={report.test?.pt?.validation?.unit?.values}
          />
          <Evidences phases={translatedReport.phase} />
          <Signatures report={translatedReport} />
        </div>
      </div>
    )
  );
};

export default ReportForms;
