import React, { useEffect } from 'react';
import { Layout, Menu, ConfigProvider, notification } from 'antd';
import CategoryDropdown from '../CategoryDropdown/CategoryChats';
import useVirtualEngineerState, { currentEquipment } from '../../atoms/variables';
import { useNavigate } from 'react-router-dom';
import { removeDuplicates, scrollDown } from '../../helpers/misc';
import { getUserConversationHistory } from '../../helpers/chat';
import { useMsal } from '@azure/msal-react';
import useGlobalState from '../../../Template/atoms/variables';
import { useTranslation } from 'react-i18next';
import './style.css';

const { Sider } = Layout;

const ChatComponent = ({ collapsed }) => {
  const { t } = useTranslation();
  const { setSiderVisible } = useGlobalState();
  const {
    conversations,
    currentThreadId,
    currentCategory,
    currentEquipment,
    setCurrentThreadId,
    setConversations,
    setCurrentEquipment,
    setCurrentLocationId,
    setCurrentEquipmentId,
  } = useVirtualEngineerState();

  const { setPendingApiCalls } = useGlobalState();

  const navigate = useNavigate();

  const [notificationApi, contextHolder] = notification.useNotification();

  const { instance } = useMsal();

  useEffect(() => {
    if (currentCategory) triggerApiCall();
  }, [currentCategory]);

  const triggerApiCall = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['getUserConversationHistory']));
      const response = await getUserConversationHistory(instance, currentCategory);
      const tmp = [...conversations];
      const updatedConversations = removeDuplicates(
        tmp
          .filter((conversation) => conversation?.subject.category === currentCategory.id)
          .concat(response.items?.length > 0 ? response.items : []),
        '_id',
      );
      setConversations((current) => {
        const updatedConversations = removeDuplicates(
          current
            .filter((conversation) => conversation?.subject.category === currentCategory.id)
            .concat(response.items?.length > 0 ? response.items : []),
          '_id',
        );
        return updatedConversations;
      });

      if (updatedConversations.length == 0) {
        notificationApi.info({
          message: t('empty_database'),
          description: t('missing_items_description'),
          duration: 5,
        });
      }
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
      console.log(err);
    } finally {
      setPendingApiCalls((current) =>
        current.filter((item) => item != 'getUserConversationHistory'),
      );
    }
  };

  const handleMenuClick = ({ key }) => {
    setCurrentThreadId(key);
    const currentConversation = conversations.find((conversation) => conversation._id == key);
    setCurrentEquipment(currentConversation.subject);
    setCurrentEquipmentId(currentConversation.subject?._id);
    setCurrentLocationId(currentConversation.subject?.parent);
    if (window.innerWidth <= 440) {
      setSiderVisible(false);
    }
    navigate(`/virtual-engineer/equipments/${currentConversation.subject._id}/threads/${key}`);
    scrollDown();
  };

  return (
    <Layout className={`sider-chats ${collapsed ? 'collapsed' : ''}`} style={{ height: '100%' }}>
      {contextHolder}
      <Sider
        style={{ background: '#fff' }}
        width={'20rem'}
        collapsed={collapsed}
        collapsible
        collapsedWidth={0}
        trigger={null}
      >
        <div
          style={{
            padding: '1rem',
          }}
        >
          <CategoryDropdown />
        </div>

        <ConfigProvider
          theme={{
            components: {
              Menu: {
                itemSelectedBg: '#F4F4F5',
                itemHoverBg: '#F4F4F5',
                itemSelectedColor: 'white',
                colorIcon: 'white',
                subMenuItemBg: 'white',
              },
            },
          }}
        >
          <Menu
            key={`Sidemenu-conversations-${currentCategory ?? 'nothing-selected'}`}
            onClick={handleMenuClick}
            selectedKeys={[currentThreadId]}
            mode='inline'
            items={conversations.map((conversation) => ({
              label:
                conversation.messages?.[conversation.messages.length - 1]?.value?.length > 0
                  ? conversation.messages?.[conversation.messages.length - 1]?.value
                  : '...',
              key: conversation._id,
            }))}
            className='custom-menu-chats'
          />
        </ConfigProvider>
      </Sider>
    </Layout>
  );
};

export default ChatComponent;
