const Evidences = ({ phases }) => {
  const images = phases
    .filter((phase) => phase.type === 'camera' || phase.type === 'evidency')
    .map((phase) =>
      phase.steps.map((step) =>
        step.images.map((image) => {
          return { url: image.url, title: step.title };
        }),
      ),
    )
    .flat(2);
  return (
    images.length > 0 && (
      <div className='report-table'>
        <div className='report-table-title'>
          <p style={{ margin: '0 0 0 10px', color: 'white', fontWeight: '500' }}>Evidências</p>
        </div>
        <div className='evidences'>
          {images.map((image) => (
            <div key={image.title}>
              <img
                src={image.url}
                alt={`Evidência ${image.title}`}
                style={{ borderRadius: '5px' }}
              />
              <p
                style={{
                  margin: '1vh',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  color: '#225968',
                }}
              >
                {image.title?.toUpperCase()}
              </p>
            </div>
          ))}
        </div>
      </div>
    )
  );
};

export default Evidences;
