import { useNavigate } from 'react-router-dom';
import Grid from '../../../../Template/layoutComponents/Grid';
import { useTranslation } from 'react-i18next';
import useMaintenanceState from '../../../atoms/variables';

import { getNewPlant } from '../../../helpers/schemas';
import './style.css';
import { useEffect } from 'react';
import { getExistingAssetTrees } from '../../../helpers/apiCalls';
import { notification } from 'antd';
import useTemplateState from '../../../../Template/atoms/variables';
import { useMsal } from '@azure/msal-react';

const PlantsGrid = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { existingAssetTrees, setChosenPlant, setExistingAssetTrees } = useMaintenanceState();

  const { setPendingApiCalls } = useTemplateState();
  const { instance } = useMsal();

  const [notificationApi, contextHolder] = notification.useNotification();

  const handleClickNew = () => {
    setChosenPlant(getNewPlant(t));
    navigate(`/maintenance/plants/newPlant`);
  };
  const handleClickItem = (item) => {
    setChosenPlant(item);
    navigate(`/maintenance/plants/${item?.id}`);
  };

  useEffect(() => {
    triggerApiCalls();
  }, []);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['getExistingAssetTrees']));

      const response = await getExistingAssetTrees(instance);

      setExistingAssetTrees(response);
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
      console.log(err);
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'getExistingAssetTrees'));
    }
  };

  const filteredItems = existingAssetTrees?.filter((item) => item?.id !== 'newPlant') || [];

  return (
    <>
      {contextHolder}{' '}
      <Grid items={filteredItems} onClickItem={handleClickItem} onClickNew={handleClickNew} />
    </>
  );
};

export default PlantsGrid;
