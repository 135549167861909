import { useEffect, useState } from 'react';
import './style.css';
import { useTranslation } from 'react-i18next';

const Input = ({
  value,
  icon,
  placeholder,
  type,
  style,
  onChange,
  disabled,
  onBlur,
  maxLength,
}) => {
  const { t } = useTranslation();

  const [finalValue, setFinalValue] = useState(value);

  const [disallowedNames, setDisallowedNames] = useState([
    t('new_category'),
    t('new_property'),
    t('new_plant'),
    t('new_area'),
    t('new_sub_area'),
    t('new_line'),
    t('new_machine'),
    t('new_assembly'),
    t('new_equipment'),
    t('new_component'),
    t('new_instrument'),
    t('new_system'),
    t('new_cost_center'),
    t('new_manufacturer'),
    t('new_organization'),
    t('new_department'),
    t('new_interconnection'),
    t('new_user'),
    t('new_user_group'),
    t('new_executor'),
  ]);

  useEffect(() => {
    setDisallowedNames([
      t('new_category'),
      t('new_property'),
      t('new_plant'),
      t('new_area'),
      t('new_sub_area'),
      t('new_line'),
      t('new_machine'),
      t('new_assembly'),
      t('new_equipment'),
      t('new_component'),
      t('new_instrument'),
      t('new_system'),
      t('new_cost_center'),
      t('new_manufacturer'),
      t('new_organization'),
      t('new_department'),
      t('new_interconnection'),
      t('new_user'),
      t('new_user_group'),
      t('new_executor'),
    ]);
  }, [t]);

  // useEffect(() => {
  //   setFinalValue(value); // Atualiza o valor exibido quando o idioma muda
  // }, [value]);

  return (
    <div className='inputContainer'>
      {icon && <div className='inputIconContainer'>{icon}</div>}
      <input
        disabled={disabled}
        value={
          disallowedNames.some(
            (name) =>
              name.toLocaleLowerCase().replace(/ /g, '') ===
              `${value}`?.toLocaleLowerCase().replace(/ /g, ''),
          )
            ? ''
            : type === 'number'
              ? finalValue
              : value
        }
        placeholder={type === 'number' ? finalValue : placeholder}
        style={{ ...style, paddingLeft: icon ? '3rem' : '' }}
        className={`customInput ${disabled ? 'disabled' : ''}`}
        onChange={(e) => {
          if (type === 'number') {
            if (finalValue.includes('.')) {
              const lastChar = e.target.value.slice(-1);
              if (lastChar === ',' || lastChar === '.') {
                return;
              }
            }
            setFinalValue(e.target.value.replace(/[^\d\.\,]*/g, '').replace(/\,/g, '.'));
          }
          onChange(
            type === 'number'
              ? { target: { value: e.target.value.replace(/[^\d\.\,]*/g, '').replace(/\,/g, '.') } }
              : e,
          );
        }}
        maxLength={maxLength}
        onBlur={onBlur ? onBlur : () => {}}
      />
    </div>
  );
};

export default Input;
