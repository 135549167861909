import './style.css';

const Checkbox = ({ label = 'label', onChange, checked, style, disabled }) => {
  return (
    <div className={`checkboxContainer ${disabled ? 'disabled' : ''}`} style={style}>
      {label?.length > 0 && <div className='checkboxLabel'>{label}:</div>}
      <input
        style={disabled ? { cursor: 'not-allowed', backgroundColor: '#f5f7f9' } : {}}
        className={`customCheckbox ${disabled ? 'disabled' : ''}`}
        type='checkbox'
        checked={checked}
        disabled={disabled}
        onChange={(e) => {
          onChange({ target: { value: e.target.checked, checked: e.target.checked } });
        }}
      />
    </div>
  );
};

export default Checkbox;
