import { useNavigate, useParams } from 'react-router-dom';
import Tabs from '../../../../../Template/layoutComponents/Tabs';
import { notification } from 'antd';
import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';

import UploadDocumentPage from '../../../../../Template/layoutComponents/UploadDocumentPage/index';

import { deleteFile, getFiles, saveFile } from '../../../../helpers/files';

import GeneralTab from '../../../../components/GeneralTab';

import './style.css';
import { getInterconnectionCategories } from '../../../../../Settings/helpers/apiCalls';
import {
  getExistingAssetTrees,
  getInterconnectionById,
  newInterconnection,
  updateInterconnection,
} from '../../../../helpers/apiCalls';
import useTemplateState from '../../../../../Template/atoms/variables';
import { useEffect } from 'react';
import useMaintenanceState from '../../../../atoms/variables';
import CommissioningTab from '../../Details/components/CommissioningTab';

import WosDetailPage from '../components/wosDetailPage';

const InterconnectionDetails = () => {
  const { t } = useTranslation();
  const { plantId, interconnectionId } = useParams();
  const { instance } = useMsal();
  const [notificationApi, contextHolder] = notification.useNotification();

  const { companyInfo, setPendingApiCalls } = useTemplateState();
  const { setViewDetailsItem, viewDetailsItem } = useMaintenanceState();

  const updateCurrentEditingItem = (setEditing, allCategories, current, inputIndex, event) => {
    setEditing(true);
    let chosenCategory;
    // Search by current selected ID
    chosenCategory = allCategories?.find((cat) => {
      return cat.id === event.target.value;
    });

    // If not found, search by selected Categoy in 'Categoria' property
    if (!chosenCategory)
      chosenCategory = allCategories?.find((cat) => {
        const catProp = current.properties.find((prop) => prop?.key === 'category' && prop?.main);

        return cat.id === catProp?.value?.id;
      });

    const chosenCategoryProperties = chosenCategory ? [...chosenCategory?.properties] : [];

    let updateName = false;

    return {
      ...current,
      properties: [
        ...current.properties.map((item, index) => {
          if (index === inputIndex) {
            if (item.label.toLowerCase() === 'nome') {
              updateName = event.target.value;
            }

            return {
              ...item,
              value: item.type.toLowerCase().includes('select')
                ? Array.isArray(event.target.value)
                  ? event.target.value.map((value) => ({
                      id: value,
                      label: '',
                    }))
                  : { id: event.target.value, label: '' }
                : item.type.toLowerCase().includes('bool')
                  ? event.target.value
                  : event.target.value,
              // event.target.value?.replace(/ /g, ''),
            };
          }
          return item;
        }),
      ]
        .filter(
          (prop) =>
            prop.main ||
            chosenCategoryProperties.some((currentCatProp) => currentCatProp.label == prop.label),
        )
        .concat(
          chosenCategory?.properties
            ?.filter((prop) => !prop.main)
            .filter(
              (prop) =>
                !current.properties.some(
                  (currentProp) => !currentProp.main && currentProp.label == prop.label,
                ),
            ) ?? [],
        ),
      name: updateName ? updateName : current.name,
    };
  };

  useEffect(() => {
    if (
      !viewDetailsItem ||
      (interconnectionId !== 'newInterconnection' &&
        (viewDetailsItem.purchase_date || viewDetailsItem.token))
    ) {
      triggerApiCalls();
    }
  }, []);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['getInterconnectionById']));

      const response = await getInterconnectionById(instance, interconnectionId);

      setViewDetailsItem(response);
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'getInterconnectionById'));
    }
  };

  return (
    <div id='assetDetails'>
      {contextHolder}
      <Tabs
        chosenTabStyleOverride={{ padding: '0 3rem 3rem 3rem', position: 'relative' }}
        tabs={[
          {
            label: t('general_tab'),
            content: (
              <GeneralTab
                create={newInterconnection}
                update={updateInterconnection}
                getCategories={getInterconnectionCategories}
                updateCurrentEditingItem={updateCurrentEditingItem}
                goBackTo={`/maintenance/plants/${plantId}/interconnections`}
              />
            ),
          },
          {
            label: t('commissioning_tab'),
            content: <CommissioningTab steps={[{ label: 'L2' }, { label: t('operating') }]} />,
            disabled: interconnectionId.includes('new') || !companyInfo?.commissioning,
          },
          {
            label: t('work_orders_tab'),
            content: <WosDetailPage />,
            disabled: interconnectionId.includes('new') || !companyInfo?.commissioning,
          },
          {
            label: t('documents_tab'),
            content: (
              <UploadDocumentPage
                id={interconnectionId}
                type={'interconnection'}
                getCall={getFiles}
                uploadCall={saveFile}
                deleteCall={deleteFile}
              />
            ),
            disabled: interconnectionId === 'newInterconnection',
          },
        ].filter((tab) => tab)}
        defaultOpen={0}
      />
    </div>
  );
};

export default InterconnectionDetails;
