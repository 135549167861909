import { Outlet, useNavigate } from 'react-router-dom';
import useAssetTreeState from '../../atoms/variables';
import { useEffect } from 'react';
import useGlobalState from '../../../Template/atoms/variables';
import { notification } from 'antd';
import { sleep } from '../../../helpers/misc';
import { getHierarchy } from '../../helpers/apiCalls';
import { useMsal } from '@azure/msal-react';

const AssetTreeOutlet = () => {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const { setPendingApiCalls } = useGlobalState();
  const { chosenPositions, setChosenPositions } = useAssetTreeState();

  const [notificationApi, contextHolder] = notification.useNotification();

  useEffect(() => {
    // Call BFF to get user positions

    triggerApiCalls();
  }, []);

  const triggerApiCalls = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['getHierarchy']));

      const response = await getHierarchy(instance);

      if (response?.length > 0) {
        setChosenPositions(response);
        // navigate('/settings/asset-tree/manage');
      } else {
        navigate('/settings/asset-tree/build');
      }
    } catch (err) {
      notificationApi.error({
        message: 'Falha ao carregar hierarquia',
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
      console.log(err);
    } finally {
      setPendingApiCalls((current) => current.filter((item) => item !== 'getHierarchy'));
    }
  };

  return (
    <>
      {contextHolder}
      <Outlet />
    </>
  );
};

export default AssetTreeOutlet;
