import React, { useEffect, useState } from 'react';
import { Button, Typography, Card, Row, Col, notification } from 'antd';
import { StarOutlined } from '@ant-design/icons';
import NewChatModal from '../../components/VirtualEngineerButtons/components/NewChatModal';
import { useMsal } from '@azure/msal-react';
import virtualServiceLogo from '../../images/virtual_service.png';

import useVirtualEngineerState from '../../atoms/variables';
import { useTranslation } from 'react-i18next';

import useGlobalState from '../../../Template/atoms/variables';

import './style.css';
import FavoritesTable from '../../components/VirtualEngineerButtons/components/FavoritesTable';
import { getEquipment } from '../../helpers/equipment';

const { Title, Paragraph } = Typography;

const VirtualEngineerHome = () => {
  const { favorites, tablePage, pageCount, setEquipments, setTablePage, setPageCount } =
    useVirtualEngineerState();
  const [notificationApi, contextHolder] = notification.useNotification();
  const { instance } = useMsal();
  const { setPendingApiCalls } = useGlobalState();

  useEffect(() => {
    setEquipments([]);
    setTablePage(1);
    setPageCount(1);
  }, []);

  const [tableEquipments, setTableEquipments] = useState([]);

  useEffect(() => {
    getInitialTable();
  }, [favorites]);

  const getInitialTable = async () => {
    try {
      setPendingApiCalls((current) => current.concat(['getFavoritePage', 'getEquipment']));
      await getFavoritePage(1, favorites);
      const maxPage = Math.ceil(favorites.length / 9);
      setPageCount(maxPage);
      setTablePage(Math.min(1, maxPage));
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
      console.log(err);
    } finally {
      setPendingApiCalls((current) =>
        current.filter((item) => item !== 'getFavoritePage' && item !== 'getEquipment'),
      );
    }
  };

  const getPageTable = async (page) => {
    try {
      setPendingApiCalls((current) => current.concat(['getFavoritePage', 'getEquipment']));
      await getFavoritePage(page, favorites);
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
      console.log(err);
    } finally {
      setPendingApiCalls((current) =>
        current.filter((item) => item !== 'getFavoritePage' && item !== 'getEquipment'),
      );
    }
  };

  const getFavoritePage = async (page, favorites) => {
    try {
      const promises = [];
      for (let i = (page - 1) * 9; i < favorites.length && i < page * 9; i++) {
        promises.push(getEquipment(instance, favorites?.[i]?.asset_id));
      }
      const results = await Promise.all(promises);
      setTableEquipments(results);
    } catch (err) {
      notificationApi.error({
        message: t('database_communication_failure'),
        description: err?.response?.data?.message
          ? `${err?.response?.data?.message} (${err?.response?.status})`
          : err?.message,
        duration: 5,
      });
      console.log(err);
    }
  };

  const { t } = useTranslation();

  return (
    <div className='dashboard-container'>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} style={{ paddingBottom: '2rem' }}>
          <div className='logo-container'>
            <img
              src={virtualServiceLogo}
              alt={t('virtual_service_icon')}
              className='service-logo'
            />
            <div className='welcome-text'>
              <Title level={3}>{t('welcome_virtual_engineer')}</Title>
              <Paragraph>{t('virtual_engineer_intro')}</Paragraph>
            </div>
          </div>
        </Col>
        {/* <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'end',
            paddingBottom: '2rem',
          }}
        >
          <div className='row-cards'>
            <Card bordered={false} className='dashboard-card'>
              <div className='card-content'>
                <ToolOutlined className='card-icon' />
                <Title level={4}>{t('select_equipment')}</Title>
                <Paragraph>{t('select_equipment_description')}</Paragraph>
              </div>
            </Card>
            <Card bordered={false} className='dashboard-card'>
              <div className='card-content'>
                <MessageOutlined className='card-icon' />
                <Title level={4}>{t('ask_anything')}</Title>
                <Paragraph>{t('ask_anything_description')}</Paragraph>
              </div>
            </Card>
            <Card bordered={false} className='dashboard-card'>
              <div className='card-content'>
                <FileTextOutlined className='card-icon' />
                <Title level={4}>{t('generate_work_order')}</Title>
                <Paragraph>{t('generate_work_order_description')}</Paragraph>
              </div>
            </Card>
          </div>
        </Col> */}
        <Col xs={24} sm={24} md={24} lg={24} style={{ display: 'flex', justifyContent: 'center' }}>
          <Card bordered={false} className='footer-card'>
            <div className='footer-content'>
              <Paragraph className='footer-paragraph'>{t('virtual_engineer_footer')}</Paragraph>
              <NewChatModal />
            </div>
          </Card>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          style={{
            paddingTop: '2rem',
          }}
        >
          <div className='row-cards'>
            <Card bordered={false} className='dashboard-card-row'>
              <div className='card-content'>
                <StarOutlined
                  className='card-icon'
                  style={{
                    float: 'inline-start',
                    'margin-left': '1rem',
                    'margin-right': '2rem',
                  }}
                />
                <Title level={4}>{t('favorite-title')}</Title>
                <Paragraph>{t('favorite-subtitle')}</Paragraph>
                <div
                  style={{
                    'font-weight': 500,
                    float: 'right',
                  }}
                >
                  <Button
                    disabled={tablePage === 1}
                    icon='<'
                    style={{
                      height: '1rem',
                      color: 'black',
                      background: 'white',
                      border: '0px',
                    }}
                    onClick={async () => {
                      setTablePage(tablePage - 1);
                      await getPageTable(tablePage - 1);
                    }}
                  />
                  {tablePage} / {pageCount}
                  <Button
                    disabled={tablePage === pageCount}
                    icon='>'
                    style={{
                      height: '1rem',
                      color: 'black',
                      background: 'white',
                      border: '0px',
                    }}
                    onClick={async () => {
                      setTablePage(tablePage + 1);
                      await getPageTable(tablePage + 1);
                    }}
                  />
                </div>
                <FavoritesTable equipments={tableEquipments} />
              </div>
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default VirtualEngineerHome;
